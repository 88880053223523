var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-component-wrapper",
      class: "page-component-" + (_vm.component || "unknown").toLowerCase(),
    },
    [
      _c(
        "div",
        { staticClass: "page-component" },
        [
          _c("PageComponent" + _vm.component, {
            tag: "component",
            staticClass: "page-component",
            class:
              "page-component-" + _vm.componentLowerCase + " side-" + _vm.side,
            attrs: {
              options: _vm.options,
              "show-results": _vm.showResults,
              side: _vm.side,
              title: _vm.title,
              "dynamic-options": _vm.dynamicOptions,
            },
            on: { pickChoice: _vm.onPickChoice },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }