<script>
import { mapGetters } from 'vuex'
import PlayerType from '@/enum/PlayerType'

export default {
    name: 'page-component-template',
    props: {
        side: {
            type: String,
            required: true,
        },
        options: {
            type: Object,
            required: false,
            default () {
                return {}
            },
        },
        title: {
            type: String,
            required: false,
        },
        dynamicOptions: {
            type: Object,
            required: false,
            default () {
                return {}
            },
        },
    },
    computed: {
        ...mapGetters({
            currentCase: 'getCurrentCase',
            currentPage: 'getCurrentPage',
        }),
        isTeacher () {
            return this.$store.state.playerType == PlayerType.Teacher
        },
        isStudent () {
            return this.$store.state.playerType == PlayerType.Student
        },
    },
}
</script>