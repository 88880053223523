var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "not-found" } }, [
      _c("h1", [_vm._v("NOT FOUND!")]),
      _c("p", [
        _vm._v(
          "\n        The requested URL was not mapped to any component (and hit the bottom of the router)\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }