var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      {
        staticClass: "box",
        style: { width: _vm.containerSize, height: _vm.containerSize },
      },
      [
        _c("div", { staticClass: "percent" }, [
          _c(
            "svg",
            {
              ref: "svg",
              class: _vm.scale === 0.5 ? "white" : "purple",
              attrs: { viewBox: _vm.svgViewbox },
            },
            [
              _c("circle", {
                attrs: {
                  cx: _vm.calcSize2,
                  cy: _vm.calcSize2,
                  r: _vm.calcSize2,
                },
              }),
              _c("circle", {
                style: _vm.circle2style,
                attrs: {
                  cx: _vm.calcSize2,
                  cy: _vm.calcSize2,
                  r: _vm.calcSize2,
                  "data-ci": "2",
                },
              }),
            ]
          ),
          _c("div", { staticClass: "number" }, [
            _c("h2", { style: { "font-size": _vm.fontSizeH2 } }, [
              _c(
                "span",
                {
                  staticClass: "time",
                  style: {
                    "line-height": _vm.lineHeight,
                    "font-size": _vm.fontSizeTime,
                  },
                },
                [_vm._v(_vm._s(_vm.timeRemaining))]
              ),
              _c("br"),
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.$t("game.timerSeconds")) +
                  "\n                    "
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }