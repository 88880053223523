var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-player" }, [
    _vm.playVideo
      ? _c("video", {
          attrs: { src: _vm.videoSource, autoplay: "", controls: "" },
        })
      : _c(
          "div",
          {
            staticClass: "video-thumbnail",
            on: { click: _vm.onClickThumbnail },
          },
          [
            _c("img", {
              staticClass: "video-thumbnail-picture",
              attrs: { src: _vm.thumbnailSource },
            }),
            _c("img", {
              staticClass: "video-thumbnail-picture-overlay",
              attrs: { src: _vm.playOverlay },
            }),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }