var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("weight-map", {
        attrs: {
          isFirst: _vm.isFirst,
          moment: _vm.moment,
          momentId: _vm.momentId,
          momentType: _vm.momentType,
          momentsHiddenInitially: _vm.momentsHiddenInitially,
          options: _vm.options,
          points: _vm.isFirst ? [] : _vm.weightMapPlayerVotes,
          specificVoteCount: _vm.voteCount,
        },
      }),
      _c(
        "div",
        { staticClass: "counter-container" },
        [
          _c(
            "counter",
            {
              staticClass: "counter",
              attrs: {
                current: _vm.studentAnswerCount,
                type: "blue-thin",
                "slot-location": "affix",
              },
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.$t("game.answers")) + "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }