var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-component-container",
      class: _vm.useTransitions ? "with-transitions" : "without-transitions",
    },
    [
      _c(
        "transition",
        {
          attrs: { name: _vm.useTransitions ? _vm.componentTransition : null },
        },
        [
          _vm.leftComponent
            ? _c("div", [
                _vm.showResults
                  ? _c(
                      "div",
                      {
                        key: _vm.key,
                        staticClass: "components-container components-full row",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-full" },
                          [
                            _vm.showComponent
                              ? _c("page-component", {
                                  attrs: {
                                    description: _vm.description,
                                    options: _vm.resultOptions,
                                    showResults: true,
                                    title: _vm.title,
                                    dynamicOptions: _vm.dynamicOptions,
                                    component: "Choices",
                                    side: "full",
                                  },
                                  on: {
                                    goBack: _vm.onGoBack,
                                    pickChoice: _vm.onSelectResultChoice,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm.showWeightMapInput
                  ? _c(
                      "div",
                      {
                        key: _vm.key,
                        staticClass: "components-container components-full row",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-full" },
                          [
                            _vm.showComponent
                              ? _c("page-component", {
                                  attrs: {
                                    isFirst: _vm.showWeightMapInput1,
                                    momentType: _vm.momentType,
                                    momentsHiddenInitially:
                                      _vm.momentsHiddenInitially,
                                    options: _vm.leftComponentOptions,
                                    selectedChoiceIndex:
                                      _vm.selectedChoiceIndex,
                                    title: _vm.selectedChoiceTitle,
                                    dynamicOptions: _vm.dynamicOptions,
                                    component: "WeightMapInput",
                                    side: "full",
                                  },
                                  on: {
                                    goBack: _vm.onGoBack,
                                    pickChoice: _vm.onPickResultChoice,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm.showWeightMapOutput
                  ? _c(
                      "div",
                      {
                        key: _vm.key,
                        staticClass: "components-container components-full row",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-full" },
                          [
                            _vm.showComponent
                              ? _c("page-component", {
                                  attrs: {
                                    description: _vm.description,
                                    options: _vm.leftComponentOptions,
                                    selectedChoiceIndex:
                                      _vm.selectedChoiceIndex,
                                    title: _vm.selectedChoiceTitle,
                                    dynamicOptions: _vm.dynamicOptions,
                                    component: "WeightMapOutput",
                                    side: "full",
                                  },
                                  on: { goBack: _vm.onGoBack },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : !_vm.forceFull && _vm.hasTwoComponents
                  ? _c(
                      "div",
                      {
                        key: _vm.key,
                        staticClass:
                          "components-container components-side-by-side row",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-sm-6 col-left" },
                          [
                            _vm.showComponent
                              ? _c("page-component", {
                                  attrs: {
                                    component: _vm.leftComponent,
                                    description: _vm.description,
                                    options: _vm.leftComponentOptions,
                                    title: _vm.title,
                                    dynamicOptions: _vm.dynamicOptions,
                                    side: "left",
                                  },
                                  on: {
                                    continue: _vm.onContinue,
                                    end: _vm.onEnd,
                                    goBack: _vm.onGoBack,
                                    pickChoice: _vm.onPickResultChoice,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.hasTwoComponents
                          ? _c(
                              "div",
                              { staticClass: "col-sm-6 col-right" },
                              [
                                _vm.showComponent
                                  ? _c("page-component", {
                                      attrs: {
                                        component: _vm.rightComponent,
                                        description: _vm.description,
                                        options: _vm.rightComponentOptions,
                                        title: _vm.title,
                                        dynamicOptions: _vm.dynamicOptions,
                                        side: "right",
                                      },
                                      on: {
                                        continue: _vm.onContinue,
                                        end: _vm.onEnd,
                                        goBack: _vm.onGoBack,
                                        pickChoice: _vm.onPickResultChoice,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        key: _vm.key,
                        staticClass: "components-container components-full row",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-full" },
                          [
                            _vm.showComponent
                              ? _c("page-component", {
                                  attrs: {
                                    component: _vm.leftComponent,
                                    description: _vm.description,
                                    options: _vm.leftComponentOptions,
                                    title: _vm.title,
                                    dynamicOptions: _vm.dynamicOptions,
                                    side: "full",
                                  },
                                  on: {
                                    continue: _vm.onContinue,
                                    end: _vm.onEnd,
                                    goBack: _vm.onGoBack,
                                    pickChoice: _vm.onPickResultChoice,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
              ])
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "bottom-bar bottom-bar-page-component p-3" }, [
        _c(
          "div",
          { staticClass: "bottom-bar-student-overview" },
          [
            _c(
              "counter",
              {
                staticClass: "student-counter",
                attrs: {
                  current: _vm.studentCount,
                  type: "blue",
                  "slot-location": "affix",
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("room.areReady")) +
                    "\n            "
                ),
              ]
            ),
          ],
          1
        ),
        _vm.showButtons
          ? _c(
              "div",
              { staticClass: "bottom-bar-buttons text-center" },
              [
                _vm.showBackButton
                  ? _c(
                      "app-button",
                      {
                        staticClass: "mr-3",
                        attrs: { accent: "secondary" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onClickBack.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("misc.back")) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.showStartAgainButton
                  ? _c(
                      "app-button",
                      {
                        staticClass: "mr-3",
                        attrs: { accent: "secondary" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onClickPlayAgain.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.momentType === "moment"
                                ? _vm.$t("misc.startAgainMoment")
                                : _vm.$t("misc.startAgainDilemma")
                            ) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "span",
                  { staticClass: "continue-button-container" },
                  [
                    _vm.showContinueButton
                      ? _c(
                          "app-button",
                          {
                            attrs: { enabled: _vm.canContinue },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.onClickContinue.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.continueButtonText) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.buttonTimeoutSeconds > 0
                        ? _c("div", { staticClass: "button-timeout" }, [
                            _vm._v(_vm._s(_vm.buttonTimeoutSeconds)),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.currentRoomCode
          ? _c("div", { staticClass: "bottom-bar-room-info" }, [
              _c(
                "div",
                { staticClass: "small-help", on: { click: _vm.openHelp } },
                [
                  _c("no-drag-image", {
                    attrs: {
                      src: require("@/_assets/img/misc/btn_board_overlayx80.png"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "small-qr-code",
                  on: { click: _vm.openBigQrCode },
                },
                [
                  _vm.studentUrlSimple
                    ? _c("qr-code", {
                        attrs: { scale: 4, value: _vm.studentUrlSimple },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "room-info-url-emoji" },
                [
                  _c("emoji-code-current", {
                    attrs: {
                      "active-emoji-index": -1,
                      "auto-width": true,
                      "show-triangle": false,
                      value: _vm.currentRoomCode,
                      type: "tiny",
                    },
                  }),
                  _c("div", { staticClass: "student-url" }, [
                    _vm._v(_vm._s(_vm.studentUrlSimple)),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }