<template>
	<div class="text-wrapper-container">
		<div class="text-wrapper">
			<NotchHeader color="dark-blue" type="solid" margin-type="small"></NotchHeader>
			<h1 v-if="title" class="title">{{ title }}</h1>
			<div v-if="text">
				<vue-markdown class="text">{{ text }}</vue-markdown>
			</div>
		</div>
	</div>
</template>

<script>
import NotchHeader from '@/components/notch-header'
import VueMarkdown from 'vue-markdown'
import PageComponentTemplate from '@/components/page-components/page-component-template';

export default {
	name: 'page-component-dark-box',
	extends: PageComponentTemplate,
	components: {
		NotchHeader,
		VueMarkdown,
	},
	computed: {
		text () {
			if (!this.options) {
				return
			}

			if (!this.options.text) {
				return
			}

			return this.options.text
		},
	},
}
</script>

<style lang="less" scoped>
@import "~@less/variables.less";
@import "~@less/font-sizes.less";

.text-wrapper-container {
	width: 80%;
	margin: 0 auto;

	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.text-wrapper {
	border-style: none solid solid solid;
	border-color: #001233;
	background: #001233;
	border-width: @notch-border-width;
	padding: 10px 15px 15px 15px;
	margin-top: -1px;
}

.text {
	font-weight: 400;
	opacity: 0.8;
	font-size: @font-size-sm;
	color: white !important;
}

.title {
	font-weight: 700;
	font-size: @font-size-md;
	margin-bottom: 15px;
	color: white !important;
}

.page-component-text {
	&.side-full {
		padding: 0 calc(15vw);
	}
}
</style>
