<script>

export default {
    props: {
        options: {
            type: Object,
        },
        results: {
            type: Array,
        },
    },
    methods: {
        pickChoice (choiceIndex) {
            let choice = this.choices[choiceIndex]

            this.$emit('pickChoice', choice)
        },
    },
}
</script>

<style lang="less" scoped>
</style>