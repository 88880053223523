<template>
	<div class="container">
		<div class="box" :style="{ width: containerSize, height: containerSize }">
			<div class="percent">
				<svg ref="svg" :viewBox="svgViewbox" :class="scale === 0.5 ? 'white' : 'purple'">
					<circle :cx="calcSize2" :cy="calcSize2" :r="calcSize2"></circle>
                    <circle :cx="calcSize2" :cy="calcSize2" :r="calcSize2" data-ci="2" :style="circle2style"></circle>
				</svg>
				<div class="number">
					<h2 :style="{ 'font-size': fontSizeH2 }">
                        <span class="time" :style=" { 'line-height': lineHeight,'font-size': fontSizeTime }">{{ timeRemaining }}</span><br>
                        {{ $t('game.timerSeconds') }}
                    </h2>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		timeRemaining: {
			type: Number,
			default: 50,
		},
		timeTotal: {
			type: Number,
		},
		scale: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			segmentActive: 5,
			startTime: this.timeTotal,
		}
	},
	computed: {
        calcSize2() {
            return (this.calcSize / 2);
        },
	    calcSize() {
            return this.calcContainerSize - (this.svgPadding * 2);
        },
        calcContainerSize() {
            return (150 * this.scale)
        },
        containerSize() {
            return this.calcContainerSize + 'px';
        },
		width() {
			return this.calcSize + 'px'
		},
		height() {
			return this.calcSize + 'px'
		},
        fontSizeH2() {
            return (32 * this.scale) + 'px';
        },
        fontSizeTime() {
            if (this.scale === 0.5) {
                return '18px';
            }

            return (52 * this.scale) + 'px';
        },
        lineHeight() {
            if (this.scale === 0.5) {
                return '20px';
            }

            return (60 * this.scale) + 'px';
        },
        svgPadding() {
            return 10;
        },
        svgViewbox() {
            const calcSize = 150 * this.scale;

            return `${-this.svgPadding} ${-this.svgPadding} ${calcSize} ${calcSize}`;
        },
        circle2style() {
            let stroke = 440;

            if (this.scale === 0.5) {
                stroke = 180; // Nobody knows why, just go with it
            }

            return {
                'stroke-dasharray': stroke,
                'stroke-dashoffset': stroke,
            }
        },
	},
	methods: {
		formatTime(seconds) {
			return seconds
		},
		recalculateOpacities() {
			var style = getComputedStyle(document.body)

			console.log(style.getPropertyValue('--time'))
			console.log(style.getPropertyValue('--timer'))
		},
	},
	mounted() {
		const cardElement = this.$refs['svg'].querySelector('circle:nth-child(2)')
		document.documentElement.style.setProperty('--time', `${this.timeTotal}s`)
		document.documentElement.style.setProperty('--timer', this.timeTotal)

		cardElement.classList.add('start')
	},
}
</script>

<style lang="less" scoped>
@import "~@less/variables.less";

.box {
    //border: 1px solid red;
}
.timer {
	position: relative;
}

.segment {
	transition-property: opacity;
	transition-duration: 300ms;

	&.segment-active {
		opacity: 1;
	}

	&.segment-inactive {
		opacity: 0.3;
	}
}

.timer-text {
	color: white;
	font-size: @font-size-md;
	position: absolute;
	top: 50%;
	left: 50%;
	text-align: center;
	transform: translate(-50%, -50%);
	line-height: 100%;
	font-weight: 500;
}

:root {
	--time: 30s;
	--timer: 30;
}

* {
	margin: 0;
	padding: 0;
}

@property --num {
	syntax: "<integer>";
	initial-value: 0;
	inherits: false;
}

.time.start {
	animation: counter var(--time) 1 forwards linear;
	counter-reset: num var(--num);
}

.time {
	position: relative;
	padding-bottom: 10px;
}

/*.time::after {
  content: counter(num);
}*/

@keyframes counter {
	from {
		--num: 0;
	}
	to {
		--num: var(--timer);
	}
}

.timer {
	align-items: center;
	margin: 30px;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
}

.timer span {
	display: block;
	font-size: 3rem;
}

button {
	padding: 30px 10px;
	margin: 20px;
	min-width: 100px;
	font-size: 2rem;
	text-transform: uppercase;
	cursor: pointer;
}

body {
	align-items: center;
	background-color: #101010;
	display: flex;
	min-height: 100vh;
	justify-content: center;
	color: white;
}

.container {
	align-items: center;
	display: flex;
	position: relative;
	justify-content: center;
}


.percent {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	// background: #C4C4C4;
	z-index: 1000;
}

.percent .number {
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	justify-content: center;
	transform: translate(-50%, -50%);
	align-items: center;
	border-radius: 50%;
	text-align: center;
    margin-top: -2px;
}

.percent .number h2 {
	color: white;
	font-weight: 700;
	transition: 0.5s;
}

.percent .number h2 .time {
	//line-height: 60px;
}

svg {
	position: absolute;
	height: 100%;
	width: 100%;
    top: 0;
    left: 0;
	z-index: 1000;
    transform: rotate(-90deg)
}

svg {
    circle {
        height: 100%;
        width: 100%;
        fill: none;
        stroke: @color-purple;
        stroke-width: 13;
        // stroke-linecap: round;

        &:nth-child(1) {
            opacity: 0.2;
        }

        &:nth-child(2) {
            stroke: @color-purple;
        }

        &.start:nth-child(2) {
            animation: dash var(--time) linear forwards;
        }
    }
}

svg.white {
    circle {
        stroke: white !important;

        &:nth-child(2) {
            stroke: white !important;
        }

        &.start:nth-child(2) {
            animation: dash-white var(--time) linear forwards !important;
        }
    }
}

@keyframes dash {
	to {
		stroke: @color-purple;
		stroke-dashoffset: 0;
	}
}

@keyframes das-white {
    to {
        stroke: white;
        stroke-dashoffset: 0;
    }
}

</style>
