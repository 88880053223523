var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h1", [_vm._v("Sessions")]),
      _c("h3", { staticClass: "mb-2" }, [
        _vm._v(_vm._s(_vm.$t("mainMenu.language"))),
      ]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.language,
              expression: "language",
            },
          ],
          staticClass: "language-selector mb-4",
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.language = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        [
          _c("option", { attrs: { value: "all" } }, [_vm._v("All")]),
          _vm._l(_vm.languages, function (key, value) {
            return _c("option", { key: key, domProps: { value: key } }, [
              _vm._v("\n\t\t\t" + _vm._s(value) + "\n\t\t"),
            ])
          }),
        ],
        2
      ),
      _vm._l(_vm.filteredSessions, function (session) {
        return _c(
          "div",
          { key: session.id, staticClass: "mb-5" },
          [
            _c("table", { staticClass: "table table-bordered table-striped" }, [
              _c("thead", { staticClass: "thead-dark" }, [
                _c("tr", [
                  _c("th", { staticClass: "language-cell" }, [
                    _vm._v(_vm._s(session.language)),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.getTimeString(session.time)))]),
                  _c("th", [_vm._v(_vm._s(session.themeTitle))]),
                  _c("th", [_vm._v(_vm._s(session.caseTitle))]),
                ]),
              ]),
            ]),
            _vm._l(session.data.responses, function (response) {
              return _c("div", { key: response.pageId, staticClass: "mb-4" }, [
                _c("h4", [_vm._v(_vm._s(response.pageTitle))]),
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c(
                      "tr",
                      _vm._l(response.choices, function (choice, i) {
                        return _c(
                          "th",
                          { key: i, staticClass: "choice-cell" },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(choice.text) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("tbody", [
                    _c(
                      "tr",
                      _vm._l(response.votes, function (vote, i) {
                        return _c(
                          "td",
                          {
                            key: i,
                            staticClass: "vote-cell",
                            class: {
                              highest: _vm.isVoteHighest(response.votes, i),
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(vote) +
                                " (" +
                                _vm._s(_vm.getPercentage(response.votes, i)) +
                                "%)\n\t\t\t\t\t"
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ])
            }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }