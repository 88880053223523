var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result-bar" }, [
    _c("div", { staticClass: "result-bar-inner" }, [
      _c(
        "div",
        {
          staticClass: "result-bar-fill",
          style: { height: _vm.percentage + "%", background: _vm.fillColor },
        },
        [
          _c("div", { staticClass: "result-bar-percentage" }, [
            _vm._v(_vm._s(_vm.percentage) + "%"),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }