<template>
    <div>
        <media v-if="options.url" :source="options.url"/>
    </div>
</template>

<script>
import Media from '@/components/media'
import PageComponentTemplate from '../page-component-template'

export default {
    name: 'page-component-media',
    extends: PageComponentTemplate,
    components: {
        Media,
    },
}
</script>
