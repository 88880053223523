<template>
    <div :class="useTransitions ? 'with-transitions' : 'without-transitions'" class="page-component-container">
        <transition :name="useTransitions ? componentTransition : null">
            <div v-if="leftComponent">
                <div v-if="showResults" :key="key" class="components-container components-full row">
                    <div class="col-sm-12 col-full">
                        <page-component
                            v-if="showComponent"
                            :description="description"
                            :options="resultOptions"
                            :showResults="true"
                            :title="title"
                            :dynamicOptions="dynamicOptions"
                            component="Choices"
                            side="full"
                            v-on:goBack="onGoBack"
                            v-on:pickChoice="onSelectResultChoice"
                        />
                    </div>
                </div>
                <div v-else-if="showWeightMapInput" :key="key" class="components-container components-full row">
                    <div class="col-sm-12 col-full">
                        <page-component
                            v-if="showComponent"
                            :isFirst="showWeightMapInput1"
                            :momentType="momentType"
                            :momentsHiddenInitially="momentsHiddenInitially"
                            :options="leftComponentOptions"
                            :selectedChoiceIndex="selectedChoiceIndex"
                            :title="selectedChoiceTitle"
                            :dynamicOptions="dynamicOptions"
                            component="WeightMapInput"
                            side="full"
                            v-on:goBack="onGoBack"
                            v-on:pickChoice="onPickResultChoice"
                        />
                    </div>
                </div>
                <div v-else-if="showWeightMapOutput" :key="key" class="components-container components-full row">
                    <div class="col-sm-12 col-full">
                        <page-component
                            v-if="showComponent"
                            :description="description"
                            :options="leftComponentOptions"
                            :selectedChoiceIndex="selectedChoiceIndex"
                            :title="selectedChoiceTitle"
                            :dynamicOptions="dynamicOptions"
                            component="WeightMapOutput"
                            side="full"
                            v-on:goBack="onGoBack"
                        />
                    </div>
                </div>
                <div v-else-if="!forceFull && hasTwoComponents"
                     :key="key" class="components-container components-side-by-side row">
                    <div class="col-sm-6 col-left">
                        <page-component
                            v-if="showComponent"
                            :component="leftComponent"
                            :description="description"
                            :options="leftComponentOptions"
                            :title="title"
                            :dynamicOptions="dynamicOptions"
                            side="left"
                            v-on:continue="onContinue"
                            v-on:end="onEnd"
                            v-on:goBack="onGoBack"
                            v-on:pickChoice="onPickResultChoice"
                        />
                    </div>
                    <div v-if="hasTwoComponents" class="col-sm-6 col-right">
                        <page-component
                            v-if="showComponent"
                            :component="rightComponent"
                            :description="description"
                            :options="rightComponentOptions"
                            :title="title"
                            :dynamicOptions="dynamicOptions"
                            side="right"
                            v-on:continue="onContinue"
                            v-on:end="onEnd"
                            v-on:goBack="onGoBack"
                            v-on:pickChoice="onPickResultChoice"
                        />
                    </div>
                </div>
                <div v-else :key="key" class="components-container components-full row">
                    <div class="col-sm-12 col-full">
                        <page-component
                            v-if="showComponent"
                            :component="leftComponent"
                            :description="description"
                            :options="leftComponentOptions"
                            :title="title"
                            :dynamicOptions="dynamicOptions"
                            side="full"
                            v-on:continue="onContinue"
                            v-on:end="onEnd"
                            v-on:goBack="onGoBack"
                            v-on:pickChoice="onPickResultChoice"
                        />
                    </div>
                </div>
            </div>
        </transition>

        <div class="bottom-bar bottom-bar-page-component p-3">
            <div class="bottom-bar-student-overview">
                <counter :current="studentCount" :type="'blue'" class="student-counter" slot-location="affix">
                    {{ $t('room.areReady') }}
                </counter>
            </div>
            <div v-if="showButtons" class="bottom-bar-buttons text-center">
                <app-button v-if="showBackButton" accent="secondary" class="mr-3" v-on:click.native="onClickBack">
                    {{ $t('misc.back') }}
                </app-button>
                <app-button v-if="showStartAgainButton" accent="secondary" class="mr-3"
                            v-on:click.native="onClickPlayAgain">
                    {{momentType === 'moment' ? $t('misc.startAgainMoment') : $t('misc.startAgainDilemma') }}
                </app-button>
                <span class="continue-button-container">
                    <app-button v-if="showContinueButton" :enabled="canContinue" v-on:click.native="onClickContinue">
                        {{ continueButtonText }}
                    </app-button>
                    <transition name="fade">
                        <div class="button-timeout" v-if="buttonTimeoutSeconds > 0">{{buttonTimeoutSeconds}}</div>
                    </transition>
                </span>
            </div>
            <div v-if="currentRoomCode" class="bottom-bar-room-info">
                <div class="small-help" @click="openHelp">
                    <no-drag-image src="@/_assets/img/misc/btn_board_overlayx80.png"/>
                </div>
                <div class="small-qr-code" @click="openBigQrCode">
                    <qr-code v-if="studentUrlSimple" :scale="4" :value="studentUrlSimple"/>
                </div>
                <div class="room-info-url-emoji">
                    <emoji-code-current :active-emoji-index="-1" :auto-width="true" :show-triangle="false"
                                        :value="currentRoomCode"
                                        type="tiny"/>
                    <div class="student-url">{{ studentUrlSimple }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Counter from '@/components/counter'
import PageComponent from './page-component'
import PagePhase from '@/enum/PagePhase'
import GamePhase from '@/enum/GamePhase'
import AppConfig from '@/app-config'
import Breadcrumb from '@/components/breadcrumb'
import * as MutationTypes from '@/store/mutation-types'
import GameType from '@/enum/GameType'
import ResultType from '@/enum/ResultType'
import { $eBus } from '@/event-bus'
import GameClientEvent from '@/enum/GameClientEvent'
import AppButton from '@/components/app-button/index'
import EmojiCodeCurrent from '@/components/emoji-code/current-code'
import QrCode from '@/components/qr-code/index'
import TokenType from '../../enum/TokenType'

export default {
    name: 'page-component-container',
    props: {
        breadcrumbUrl: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        description: {
            type: String,
            required: false,
        },
        leftComponent: {
            type: String,
            required: false,
            default() {
                return '';
            }
        },
        leftComponentOptions: {
            type: Object,
            required: false,
            default () {
                return {}
            },
        },
        rightComponent: {
            type: String,
            required: false,
            default() {
                return '';
            }
        },
        rightComponentOptions: {
            type: Object,
            required: false,
            default () {
                return {}
            },
        },
        useTransitions: {
            type: Boolean,
            required: false,
            default: true,
        },
        currentPage: {
            type: Object,
            required: true,
        },
    },
    components: {
        EmojiCodeCurrent,
        PageComponent,
        Breadcrumb,
        AppButton,
        QrCode,
        Counter,
    },
    data () {
        return {
            componentTransition: AppConfig.componentTransition,
            selectedChoiceIndex: -1,
            showLargeQR: false,
            showHiddenMoments: false,

            buttonTimeoutSeconds: 0,
            buttonTimeout: null,
        }
    },
    mounted () {
        this.checkChoiceComponent()

        this.$store.commit(MutationTypes.SET_BREADCRUMB_TO, this.breadcrumbUrl)
        $eBus.$on(GameClientEvent.RoomMessage, this.onRoomMessage)
    },
    destroyed () {
        $eBus.$off(GameClientEvent.RoomMessage, this.onRoomMessage)
    },
    watch: {
        actualCurrentPageIndex () {
            this.checkChoiceComponent()
            this.checkButtonTimeout();

            this.showHiddenMoments = false;
        },
        currentPagePhase () {
            this.checkChoiceComponent()
            this.checkButtonTimeout();
        },
    },
    computed: {
        ...mapGetters({
            currentCase: 'getCurrentCase',
            actualCurrentPageIndex: 'getCurrentPageIndex',
            currentPagePhase: 'getCurrentPagePhase',
            pageHistory: 'getPageHistory',
            gameType: 'getGameType',
			timerTimeTotal: 'getTimerTimeTotal',
			timerTimeRemaining: 'getTimerTimeRemaining',
            currentCasePage: 'getCurrentPage',
        }),
        dynamicOptions() {
            return {
                showHiddenMoments: this.showHiddenMoments,
            };
        },
        useTransitionsRefined () {
            if (!this.showComponent) {
                return false
            }
            return this.useTransitions
        },
        showComponent () {
            if (this.currentPagePhase === PagePhase.Normal && this.leftComponent === 'Choices') {
                if (this.leftComponentOptions.hasOwnProperty('listType') && this.leftComponentOptions.listType ===
                    'singleChoice') {
                    return false
                }
                else if (this.leftComponentOptions.hasOwnProperty('listType') && this.leftComponentOptions.listType !==
                    'userAdded') {
                    return false
                }
            }
            return true
        },
        momentType () {
            return this.leftComponentOptions?.momentType || null;
        },
        momentsHiddenInitially () {
          console.log("hello I wanna see show moments from moments Hidedn Initially", this.leftComponentOptions?.momentsHiddenInitially , this.leftComponentOptions?.momentsHiddenInitially || false);
            return this.leftComponentOptions?.momentsHiddenInitially || false;
        },

        studentUrl () {
            return AppConfig.studentUrl
        },
        studentUrlSimple () {
            return AppConfig.studentUrlSimple
        },

        forceFull () {
            return this.isSinglePlayer
        },

        isSinglePlayer () {
            return this.gameType === GameType.SinglePlayer
        },

        hasTwoComponents () {
            return this.leftComponent && this.rightComponent
        },

        studentCount () {
            if (!this.$gameClient.room) {
                return 0;
            }

            if (!this.$gameClient.room.state) {
                return 0;
            }

            if (!this.$gameClient.room.state.studentCount) {
                return 0;
            }

            return this.$gameClient.room.state.studentCount;
        },

        key () {
            // This allows the transition to figure out that it needs to create a new instance of the object and fade between them
            return this.actualCurrentPageIndex + '-' + this.currentPagePhase
        },

        useResult () {
            return !this.isSinglePlayer && (this.leftComponent === 'Choices' || this.rightComponent === 'Choices')
        },

        showResults () {
            return this.currentPagePhase === PagePhase.Result
        },

        showWeightMapInput () {
            return (this.currentPagePhase === PagePhase.WeightMapInput1 || this.currentPagePhase ===
                PagePhase.WeightMapInput2)
        },

        showWeightMapOutput () {
            return (this.currentPagePhase === PagePhase.WeightMapOutput1 || this.currentPagePhase ===
                PagePhase.WeightMapOutput2)
        },

        showWeightMapInput1 () {
            return this.currentPagePhase === PagePhase.WeightMapInput1
        },

        showWeightMapOutput1 () {
            return this.currentPagePhase === PagePhase.WeightMapOutput1
        },

        tokenType() {
            return this.currentCasePage.tokenType || 'bothSameTime';
        },

        showStartAgainButton () {
            let choices;

            try {
                choices = this.resultOptions?.choices;
            } catch (e) {
                choices = [];
            }

            if (!choices) {
                choices = [];
            }

            return this.currentPagePhase === PagePhase.WeightMapOutput2 && choices.length > 0;
        },

        showWeightMapInput2 () {
            return this.currentPagePhase === PagePhase.WeightMapInput2
        },

        showWeightMapOutput2 () {
            return this.currentPagePhase === PagePhase.WeightMapOutput2
        },

        selectedChoiceTitle () {
            return 'DYNAMIC CHOICE TITLE'
        },

        resultOptions () {
            if (this.leftComponent === 'Choices') {
                return this.leftComponentOptions
            }
            else if (this.rightComponent === 'Choices') {
                return this.rightComponentOptions
            }
            else {
                throw new Error(
                    `Could not find choice component on left side (${this.leftComponent}) or right side (${this.rightComponent})`)
            }
        },

        showBreadcrumb () {
            return this.breadcrumbUrl && this.currentCase
        },

        isLastPage () {
            return this.currentPage && (this.currentPage.isLastPage || this.currentPage.lastPage)
        },
        useContinueTimeout() {
            const continueTimeoutLabelTexts = [
                this.$t('misc.showResult'),
                this.$t('misc.continue')
            ];

            // If show result or continue text, then use timeout
            return continueTimeoutLabelTexts.includes(this.continueButtonText);
        },
        continueButtonText () {
            if (this.isLastPage) {
                return this.$t('misc.finish')
            }

            if (this.showWeightMapInput2) {
                return this.$t('misc.showResult')
            }

            if (this.showBackButton || this.leftComponent !== 'Choices') {
				return this.$t('misc.next')
			} else {
				return this.$t('misc.continue')
			}
        },
        showContinueButton () {
            return true
            /*if (!this.isLeftOrFull) {
              return false
            }

            return this.component !== 'Results'*/
        },
        canContinue () {
            if (this.buttonTimeoutSeconds > 0) {
                return false;
            }

            if (this.currentPagePhase === PagePhase.Normal && this.leftComponent === 'Choices' &&
                this.resultOptions.choices.length === 0) {
                return false;
            }

            if (this.showResults && this.selectedChoiceIndex === -1) {
              return false;
            }

            return true;


            /*if (this.showResults && this.selectedChoiceIndex !== -1) {
                return true
            }
            else if (!this.showResults) {
                return true
            }
            return false*/
        },
        showBackButton () {
            if (this.currentPagePhase === PagePhase.Result || this.leftComponent === 'Choices') {
                return false
            }

            if (!this.currentPage) {
                return false
            }

            if (this.currentPage.index <= 0) {
                return false;
            }

            // Uncomment below to force avoiding going back to choices
            // const prevPage = this.currentCase.pages[this.actualCurrentPageIndex - 1];
            // if (prevPage.components.left.component == 'Choices') {
            // 	return false;
            // }

            return this.currentPage.showBackButton
        },
        showButtons () {
            return this.showContinueButton || this.showBackButton
        },

        currentRoomCode () {
            if (this.$gameClient.roomId) {
                return this.$gameClient.roomId
            }

            return null
        },

        showMoments() {

            if (this.momentsHiddenInitially && !this.showHiddenMoments) {
                return false;
            }

            return true;
        }
    },
    methods: {
        checkButtonTimeout() {
            if (this.useContinueTimeout) {
                this.buttonTimeoutSeconds = 5;

                this.tickDown(false);
            } else {
                this.clearButtonTimeout();
            }
        },
        clearButtonTimeout() {
            if (this.buttonTimeout) {
                clearTimeout(this.buttonTimeout);
            }

            this.buttonTimeout = null;
        },
        tickDown(tickDown = true) {
            if (tickDown) {
                this.buttonTimeoutSeconds--;
            }

            if (this.buttonTimeoutSeconds > 0) {
                this.clearButtonTimeout();

                this.buttonTimeout = setTimeout(() => { this.tickDown() }, 1000);
            }
        },
        onClickContinue () {

            if (!this.canContinue) {
              return;
            }

            if (this.showResults && this.selectedChoiceIndex !== -1) {
                this.$gameClient.send({
                    _selectedChoiceIndex: this.selectedChoiceIndex,
                })
            }
            else if (this.showResults && this.selectedChoiceIndex === -1) {
                return
            }

            console.log('IS LAST PAGE', this.isLastPage)

            if (this.isLastPage) {
                this.onEnd()
            }
            else {
                this.onContinue()
            }
        },
        onClickBack () {
            this.onGoBack()
        },
        onClickPlayAgain () {
            this.$gameClient.send({
                _resetWeightMapVotes: true,

                _pagePhase: PagePhase.Result,
            })
        },
        onPickResultChoice (choice) {
            this.onContinue(choice && choice.hasOwnProperty('gotoPageIndex') ? choice.gotoPageIndex : null)
        },
        onSelectResultChoice (choice) {
            this.selectedChoiceIndex = choice
        },
        onGoBack () {
            if (this.actualCurrentPageIndex === 0) {
                return
            }

            this.$gameClient.send({
                _currentPageIndex: this.actualCurrentPageIndex - 1,
            })
        },
        onEnd () {
            this.$store.commit(MutationTypes.SET_NAVIGATION_GUARD_ENABLED, false)

            this.$gameClient.send({
                _phase: GamePhase.End,
            })
        },
        openBigQrCode () {
            this.$store.commit(MutationTypes.SET_SHOW_BIG_QR_CODE, true)
        },
        openHelp() {
            this.$store.commit(MutationTypes.SET_SHOW_HELP, true)
        },
        onContinue (gotoPageIndex = null) {
            let pagePhaseChanged = false
            let skipPageChange = false;

            if (this.useResult) {
                let newPagePhase = null
                console.log('BEFORE EVERYTHING', this.momentsHiddenInitially, this.showHiddenMoments);

                if (this.momentsHiddenInitially && !this.showHiddenMoments) {
                    this.showHiddenMoments = true;

                    console.log('Changing state to show hidden moments');

                    skipPageChange = true;
                    newPagePhase = PagePhase.Voting
                }
                else if (this.currentPagePhase === PagePhase.Normal) {
                    newPagePhase = PagePhase.Voting
                }
                else if (this.currentPagePhase === PagePhase.Voting) {
                    newPagePhase = PagePhase.Result
                }
                else if (this.currentPagePhase === PagePhase.Result) {
                    if (this.tokenType === TokenType.Vertical) {
                        // Skip horizontal, and go straight to Input2
                        newPagePhase = PagePhase.WeightMapInput2;
                    } else {
                        newPagePhase = PagePhase.WeightMapInput1
                    }
                }
                else if (this.currentPagePhase === PagePhase.WeightMapInput1) {
                    if (this.tokenType === TokenType.BothSameTime) {
                        // Skip more axis and go straight to result
                        newPagePhase = PagePhase.WeightMapOutput2
                    } else if (this.tokenType === TokenType.Horizontal) {
                        // Skip vertical and go straight to result
                        newPagePhase = PagePhase.WeightMapOutput2
                    } else {
                        // Normal way of functioning, so open up the vertical version
                        newPagePhase = PagePhase.WeightMapInput2
                    }
                }
                else if (this.currentPagePhase === PagePhase.WeightMapInput2) {
                    // If WeightMapInput2 the result page is always the next page
                    newPagePhase = PagePhase.WeightMapOutput2
                }

                if (newPagePhase) {
                    console.log(`Changing phase from ${this.currentPagePhase} to ${newPagePhase}`)

                    this.$gameClient.send({
                        _pagePhase: newPagePhase,
                    })

                    pagePhaseChanged = true
                }
                else {
                    console.log(`No new page phase (currently ${this.currentPagePhase}`)
                }
            }

            if (!pagePhaseChanged && !skipPageChange) {
                let currentPageIndex = this.actualCurrentPageIndex

                if (gotoPageIndex === null) {
                    if (this.leftComponentOptions) {
                        if (this.leftComponentOptions.hasOwnProperty('gotoPageIndex')) {
                            gotoPageIndex = this.leftComponentOptions.gotoPageIndex
                        }
                        else if (this.leftComponentOptions.hasOwnProperty('gotoPageId')) {
                            gotoPageIndex = this.leftComponentOptions.gotoPageId - 1
                        }

                        //Because of mistakes in database, this is forced
                        if (this.leftComponentOptions.hasOwnProperty(
                            'resultType') && this.leftComponentOptions.resultType === ResultType.NoAnswer) {
                            gotoPageIndex = currentPageIndex + 1
                        }
                    }
                }

                if (gotoPageIndex === null || gotoPageIndex === currentPageIndex) {
                    gotoPageIndex = currentPageIndex + 1
                }

                let currentCasePages = this.currentCase.pages || []

                if (currentCasePages.length <= gotoPageIndex) {
                    // Something is wrong here, we can't go to this page phase as it's outside our page range

                    this.onEnd()

                    return
                }

                this.$gameClient.send({
                    _pagePhase: PagePhase.Normal,
                    _currentPageIndex: gotoPageIndex,
                })
            }
        },
        onRoomMessage (room, message) {
            const parts = message.split(':')
            if (parts[0] === 'TimerEvent.Complete') {
                //this.onContinue()
            }
        },
        checkChoiceComponent () {
            if (this.currentPagePhase === PagePhase.Normal && this.leftComponent === 'Choices') {
                if (this.leftComponentOptions.hasOwnProperty('listType') && this.leftComponentOptions.listType ===
                    'singleChoice') {
                    this.$gameClient.send({
                        _pagePhase: PagePhase.WeightMapInput1,
                    })

                    // when only one choice exists it will be the only one used
                    this.$gameClient.send({
                        _selectedChoiceIndex: 0,
                    })

                }
                else {
                    if (this.leftComponentOptions.hasOwnProperty('listType') && this.leftComponentOptions.listType !==
                        'userAdded') {
                        this.$gameClient.send({
                            _pagePhase: PagePhase.Voting,
                        })
                    }
                }
            }

            if (this.currentPagePhase === PagePhase.Voting && this.leftComponent === 'Choices') {
                if (this.leftComponentOptions.hasOwnProperty('resultType') && this.leftComponentOptions.resultType ===
                    'chooseAnswer') {
                    this.$gameClient.send({
                        _pagePhase: PagePhase.Result,
                    })
                }
            }
        },
    },
}
</script>

<style lang="less">
@import "~@less/variables.less";

.bottom-bar.bottom-bar-page-component {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;
    z-index: 999;
    background: @color-light-grey;

    .bottom-bar-student-overview {
        position: absolute;
        left: 25px;
        top: 0;
    }

    .bottom-bar-room-info {
        position: absolute;
        bottom: 5px;
        right: 10px;
        display: flex;

        .room-info-url-emoji {
            margin-left: 10px;

            .student-url {
                color: white;
                font-size: 14px;
            }
        }

        .small-qr-code, .small-help {
            overflow: hidden;
            border-radius: 5px;
        }

        .small-qr-code {
            cursor: pointer;
            z-index: 1000;
            max-width: 60px;
            max-height: 60px;
        }

        .small-help {
            margin-right: 10px;
            cursor: pointer;
            z-index: 1000;
            max-width: 60px;
            max-height: 60px;

            img {
                width: 100%;
                height: 100%;
            }
        }

    }

    .bottom-bar-buttons {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.page-component-container.with-transitions {
    .components-container {
        position: absolute;
        left: 0;
        width: 100%;
        padding-bottom: 192px;
    }
}

.components-container {
    margin-left: 0 !important;
    margin-bottom: 40px;
}

.continue-button-container {
    position: relative;
}

.button-timeout {
    position: absolute;
    top: -25px;
    right: -25px;
    border-radius: 25px;
    background: #009864;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    font-size: 18pt;
    text-align: center;
    color: white;
}
</style>
