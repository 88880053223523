<template>
	<div class="container">
		<h1>Sessions</h1>
		<h3 class="mb-2">{{ $t('mainMenu.language') }}</h3>
		<select v-model="language" class="language-selector mb-4">
			<option value="all">All</option>
			<option v-for="(key, value) in languages" v-bind:key="key" :value="key">
				{{ value }}
			</option>
		</select>
		<div v-for="session in filteredSessions" :key="session.id" class="mb-5">
			<table class="table table-bordered table-striped">
				<thead class="thead-dark">
				<tr>
					<th class="language-cell">{{ session.language }}</th>
					<th>{{ getTimeString(session.time) }}</th>
					<th>{{ session.themeTitle }}</th>
					<th>{{ session.caseTitle }}</th>
				</tr>
				</thead>
			</table>
			<div v-for="response in session.data.responses" :key="response.pageId" class="mb-4">
				<h4>{{ response.pageTitle }}</h4>
				<table class="table">
					<thead>
					<tr>
						<th v-for="(choice, i) in response.choices" :key="i" class="choice-cell">
							{{ choice.text }}
						</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td v-for="(vote, i) in response.votes" v-bind:key="i"
							:class="{'highest': isVoteHighest(response.votes, i)}"
							class="vote-cell">
							{{ vote }} ({{ getPercentage(response.votes, i) }}%)
						</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import {CurrentUrlInfo} from '@/current-url-info'
import AppConfig from '@/app-config'
import Language from '@/enum/Language'

export default {
	data() {
		return {
			sessions: [],
			language: 'all',
		}
	},
	mounted() {
		let currentUrlInfo = CurrentUrlInfo.instance()
		let host = AppConfig.colyseusHost || currentUrlInfo.getCurrentHost()
		let port = AppConfig.colyseusPort
		//this.$apiClient.onGameClientEndPointChanged(host + ':' + port)

		this.$apiClient.getSessions((sessions) => {
			this.setupSessions(sessions)
		}, () => {
		})
	},
	computed: {
		languages() {
			return Language
		},
		filteredSessions() {
			let sorted = this.sessions.sort((a, b) => {
				return new Date(b.time) - new Date(a.time)
			})

			if (this.language === 'all') {
				return sorted
			}

			return sorted.filter((session) => session.language === this.language)
		},
	},
	methods: {
		setupSessions(sessions) {
			this.sessions = []
			for (const session of sessions) {
				this.$apiClient.getCase(session.caseId, (_case) => {
					session.caseTitle = _case.title
					this.$apiClient.getTheme(_case.themeId, (theme) => {
						session.themeTitle = theme.title
						let pagesFetched = 0
						for (const response of session.data.responses) {
							this.$apiClient.getPage(response.pageId, (page) => {
								response.pageTitle = page.title
								response.choices = page.components.left.options.choices
								pagesFetched++
								if (pagesFetched === session.data.responses.length) {
									this.sessions.push(session)
								}
							}, () => {
							})
						}
					}, () => {
					})
				}, () => {
				})
			}
		},
		getTimeString(time) {
			const date = new Date(time)
			const format = {year: 'numeric', month: '2-digit', day: '2-digit'}
			if (this.language === 'all') {
				return date.toLocaleDateString('en', format)
			}
			return date.toLocaleDateString(this.language, format)
		},
		getTotalVotes(votes) {
			let total = 0
			for (const vote of votes) {
				total += vote
			}
			return total
		},
		isVoteHighest(votes, index) {
			const amount = votes[index]
			if (amount === 0) {
				return false
			}

			for (let i = 0; i < votes.length; i++) {
				if (i === index) {
					continue
				}

				if (votes[i] > amount) {
					return false
				}
			}

			return true
		},
		getPercentage(votes, index) {
			const total = this.getTotalVotes(votes)

			if (total === 0) {
				return 0
			}

			return this.round((votes[index] / total) * 100, 2)
		},
		round(value, decimals) {
			const factor = Math.pow(10, decimals)
			return Math.round(value * factor) / factor
		},
	},
}
</script>

<style lang="less" scoped>
@import "~@less/variables.less";

.language-selector {
	padding: 5px 10px;
	border: solid black 1px;
	font-size: @font-size-sm;
	font-weight: bold;
	outline: none;
}

.language-cell {
	text-transform: capitalize;
}

.choice-cell {
	font-size: @font-size-xs;
}

.vote-cell {
	font-size: @font-size-sm;
	text-align: center;

	&.highest {
		font-weight: 900;
	}
}

.total-votes-cell {
	text-decoration: underline;
}
</style>
