var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "bg-image-container",
    style: _vm.containerStyle,
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }