var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card text-white bg-dark" }, [
    _c("div", { staticClass: "card-header row mx-0 px-2" }, [
      _c("div", { staticClass: "col-8" }, [
        _vm._v(
          "\n\t\t\t#" +
            _vm._s(_vm.page.index + 1) +
            " - " +
            _vm._s(_vm.title) +
            "\n\t\t"
        ),
      ]),
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "row mx-0" }, [
          _c("div", { staticClass: "col px-1" }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isExpanded,
                    expression: "isExpanded",
                  },
                ],
                staticClass: "btn btn-warning btn-sm w-100",
                on: {
                  click: function ($event) {
                    return _vm.togglePanel()
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\t\tCollapse\n\t\t\t\t\t")]
            ),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isExpanded,
                    expression: "!isExpanded",
                  },
                ],
                staticClass: "btn btn-success btn-sm w-100",
                on: {
                  click: function ($event) {
                    return _vm.togglePanel()
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\t\tExpand\n\t\t\t\t\t")]
            ),
          ]),
          _c("div", { staticClass: "col px-1" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm w-100",
                attrs: { disabled: _vm.page.lastPage == true },
                on: {
                  click: function ($event) {
                    return _vm.moveDown()
                  },
                },
              },
              [_vm._v("Down\n\t\t\t\t\t")]
            ),
          ]),
          _c("div", { staticClass: "col px-1" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm w-100",
                attrs: { disabled: _vm.page.index == 0 },
                on: {
                  click: function ($event) {
                    return _vm.moveUp()
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\t\tUp\n\t\t\t\t\t")]
            ),
          ]),
          _c("div", { staticClass: "col px-1" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-danger btn-sm w-100",
                on: {
                  click: function ($event) {
                    return _vm.deletePage()
                  },
                },
              },
              [_vm._v("Delete")]
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "collapse", class: { show: _vm.isExpanded } }, [
      _c(
        "div",
        { staticClass: "card-body py-1" },
        [
          _c("form-row", { attrs: { label: "Title" } }, [
            _c("input", {
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.title },
              on: { blur: _vm.onTitleFieldBlur },
            }),
          ]),
          _c("form-row", { attrs: { label: "Timer (s) (Optional)" } }, [
            _c("input", {
              staticClass: "form-control",
              attrs: { type: "number" },
              domProps: { value: _vm.timer },
              on: { blur: _vm.onTimerFieldBlur },
            }),
          ]),
          _c("form-row", { attrs: { label: "Show Back Button" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.showBackButton,
                  expression: "showBackButton",
                },
              ],
              staticClass: "form-control ",
              attrs: {
                disabled:
                  _vm.page.index === 0 || _vm.prevComponentType === "Choices",
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.showBackButton)
                  ? _vm._i(_vm.showBackButton, null) > -1
                  : _vm.showBackButton,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.showBackButton,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.showBackButton = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.showBackButton = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.showBackButton = $$c
                  }
                },
              },
            }),
          ]),
          _vm.page.type == "dilemma"
            ? _c("form-row", { attrs: { label: "Components" } }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.numOfComponents,
                        expression: "numOfComponents",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.numOfComponents = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.numOfComponents == 1
            ? _c(
                "div",
                [
                  _c("component-editor", {
                    attrs: {
                      content: _vm.page.components.left,
                      pageIndex: _vm.page.index,
                      pageType: _vm.page.type,
                      pagesInCase: _vm.pagesInCase,
                      position: "Full",
                    },
                    on: {
                      updated: function ($event) {
                        return _vm.onComponentUpdated("left", $event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm.numOfComponents == 2
            ? _c(
                "div",
                [
                  _c("component-editor", {
                    attrs: {
                      content: _vm.page.components.left,
                      pageIndex: _vm.page.index,
                      pageType: _vm.page.type,
                      pagesInCase: _vm.pagesInCase,
                      position: "Left",
                    },
                    on: {
                      updated: function ($event) {
                        return _vm.onComponentUpdated("left", $event)
                      },
                    },
                  }),
                  _c("component-editor", {
                    attrs: {
                      content: _vm.page.components.right,
                      pageIndex: _vm.page.index,
                      pageType: _vm.page.type,
                      pagesInCase: _vm.pagesInCase,
                      position: "Right",
                    },
                    on: {
                      updated: function ($event) {
                        return _vm.onComponentUpdated("right", $event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }