import * as queryString from 'query-string'

export class CurrentUrlInfo {
    static _instance = null

    currentHost = null
    currentPort = null

    constructor () {
        const parsed = queryString.parse(location.search)

        // Read port
        if (parsed.port) {
            this.currentPort = parseInt(parsed.port, 10)
        }

        // Read host
        if (parsed.host) {
            this.currentHost = parsed.host
        }
        else if (window.location.host) {
            this.currentHost = window.location.host
        }

        // Remove port from host
        this.currentHost = this.currentHost.replace(/:(\d+)$/, (match) => {
            if (!this.currentPort) {
                this.currentPort = parseInt(match[1], 10)
            }

            return ''
        })
    }

    static instance () {
        if (this._instance === null) {
            this._instance = new CurrentUrlInfo()
        }

        return this._instance
    }

    getCurrentHost () {
        return this.currentHost
    }

    getCurrentPort () {
        return this.currentPort
    }

    getUrl (scheme, appendPath = null, port = null) {
        return scheme + '://' + this.getCurrentHost() + ':' +
            (port || this.getCurrentPort())
    }
}