var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "results row mt-3" }, [
    _c("div", { staticClass: "col text-center" }, [
      _c("strong", { staticClass: "your-answers-headline" }, [
        _vm._v("Your answers"),
      ]),
      _c(
        "div",
        { staticClass: "result-bubble-container" },
        _vm._l(_vm.sortedResults, function (result, i) {
          return i < 4
            ? _c("result-bubble", {
                key: i,
                staticClass: "result-bubble",
                attrs: {
                  anchor: _vm.resultOptions[i].anchor,
                  "fill-color": _vm.resultOptions[i].fillColor,
                  percentage: Math.round(result.percentage),
                },
              })
            : _vm._e()
        }),
        1
      ),
    ]),
    _c("div", { staticClass: "col" }, [
      _c("div"),
      _c("div", [
        _c(
          "ul",
          { staticClass: "result-legend mt-5" },
          _vm._l(_vm.sortedResults, function (result, i) {
            return _c("li", [
              i >= 4
                ? _c("span", { staticClass: "result-ball result-ball-empty" })
                : _c("span", {
                    staticClass: "result-ball",
                    style: { background: _vm.resultOptions[i].fillColor },
                  }),
              _c("span", { staticClass: "result-legend" }, [
                _vm._v(
                  _vm._s(Math.round(result.percentage)) +
                    "%: " +
                    _vm._s(result.message)
                ),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }