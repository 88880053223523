import { getLanguages } from '../utils/LanguageUtil'

let realLanguages = getLanguages();

let languages = {};

realLanguages.forEach(language => {
	languages[language.label] = language.code;
});

export default languages;