var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "weight-map-container" }, [
    _vm.babylonInitiated
      ? _c("div", { staticClass: "ui-elements" }, [
          _c(
            "div",
            { staticClass: "instruction-wrapper" },
            _vm._l(_vm.instructions, function (instruction, i) {
              return _c(
                "div",
                { staticClass: "wireframe-space" },
                [
                  _c("text-wire-border", {
                    attrs: {
                      text: instruction,
                      title: _vm.$t("instructions.title"),
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
          _vm.moment
            ? _c(
                "div",
                { staticClass: "moment-wrapper" },
                [
                  _c("choice", {
                    attrs: {
                      choiceType: "multipleAnswers",
                      choices: [{ text: _vm.moment }],
                      selectable: false,
                      showResults: true,
                      specificChoice: true,
                      specificId: _vm.momentId,
                      specificVoteCount: _vm.specificVoteCount,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "arrow-wrapper" }, [
            _c("i", {
              staticClass: "fas fa-share arrow-turn-ccw",
              on: {
                click: function ($event) {
                  return _vm.turnBoard(90)
                },
              },
            }),
            _c("i", {
              staticClass: "fas fa-reply arrow-turn-cw",
              on: {
                click: function ($event) {
                  return _vm.turnBoard(-90)
                },
              },
            }),
          ]),
        ])
      : _vm._e(),
    !_vm.babylonInitiated
      ? _c("div", { staticClass: "loading-container" }, [
          _c("span", { staticClass: "loading-text" }, [
            _vm._v("Loading game board.."),
          ]),
        ])
      : _vm._e(),
    _c("canvas", { ref: "babylonCanvas", attrs: { "touch-action": "none" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }