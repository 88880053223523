<template>
	<div class="card text-white bg-dark">
		<div class="card-header row mx-0 px-2">
			<div class="col-8">
				#{{ page.index + 1 }} - {{ title }}
			</div>
			<div class="col">
				<div class="row mx-0">
					<div class="col px-1">
						<button v-show="isExpanded" class="btn btn-warning btn-sm w-100" v-on:click="togglePanel()">
							Collapse
						</button>
						<button v-show="!isExpanded" class="btn btn-success btn-sm w-100" v-on:click="togglePanel()">
							Expand
						</button>
					</div>
					<div class="col px-1">
						<button :disabled="page.lastPage == true" class="btn btn-primary btn-sm w-100"
								v-on:click="moveDown()">Down
						</button>
					</div>
					<div class="col px-1">
						<button :disabled="page.index == 0" class="btn btn-primary btn-sm w-100" v-on:click="moveUp()">
							Up
						</button>
					</div>
					<div class="col px-1">
						<button class="btn btn-danger btn-sm w-100" v-on:click="deletePage()">Delete</button>
					</div>
				</div>
			</div>
		</div>
		<div class="collapse" v-bind:class="{ show: isExpanded }">
			<div class="card-body py-1">
				<form-row label="Title">
					<input :value="title" class="form-control" type="text" v-on:blur="onTitleFieldBlur"/>
				</form-row>
				<form-row label="Timer (s) (Optional)">
					<input :value="timer" class="form-control" type="number" v-on:blur="onTimerFieldBlur"/>
				</form-row>
				<form-row label="Show Back Button">
					<input v-model="showBackButton" :disabled="page.index === 0 || prevComponentType === 'Choices'"
						   class="form-control " type="checkbox"/>
				</form-row>
				<!--<form-row label="Hide Choose New Moment/Dilemma Button" v-if="page.components.left.component === 'Choices'" >
					<input v-model="hidePlayAgainButton" class="form-control " type="checkbox"/>
				</form-row>-->
				<form-row v-if="page.type == 'dilemma'" label="Components">
					<select v-model="numOfComponents" class="form-control">
						<option value="1">1</option>
						<option value="2">2</option>
					</select>
				</form-row>
				<div v-if="numOfComponents == 1">
					<component-editor :content="page.components.left" :pageIndex="page.index" :pageType="page.type"
									  :pagesInCase="pagesInCase" position="Full"
									  v-on:updated="onComponentUpdated('left', $event)"/>
				</div>
				<div v-else-if="numOfComponents == 2">
					<component-editor :content="page.components.left" :pageIndex="page.index" :pageType="page.type"
									  :pagesInCase="pagesInCase" position="Left"
									  v-on:updated="onComponentUpdated('left', $event)"/>
					<component-editor :content="page.components.right" :pageIndex="page.index" :pageType="page.type"
									  :pagesInCase="pagesInCase" position="Right"
									  v-on:updated="onComponentUpdated('right', $event)"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ComponentEditor from '@/components/component-editor'
import FormRow from '@/components/form-row'

export default {
	name: 'page-editor',
	props: {
		page: {
			type: Object,
		},
		pagesInCase: {
			type: Number,
		},
		prevPage: {
			type: Object,
			default: null,
		},
	},
	components: {
		ComponentEditor,
		FormRow,
	},
	data() {
		return {
			title: '',
			timer: 0,
			showBackButton: false,
			hidePlayAgainButton: false,
			numOfComponents: 1,
			isExpanded: false,
		}
	},
	mounted() {
		console.log('within mounted', this.page);

		if (this.page.title) {
			this.title = this.page.title
		}

		if (this.page.timer) {
			this.timer = this.page.timer
		}

		if (this.page.showBackButton) {
			this.showBackButton = this.page.showBackButton
			if (this.showBackButton && (this.page.index === 0 || this.prevComponentType === 'Choices')) {
				this.showBackButton = false
			}
		}

		if (this.page.hidePlayAgainButton) {
			this.hidePlayAgainButton = this.page.hidePlayAgainButton;
		}

		if (this.page.components.right != null) {
			this.numOfComponents = 2
		} else {
			this.numOfComponents = 1
		}

		this.$watch('showBackButton', () => {
			this.$emit('updated', {
				showBackButton: this.showBackButton,
			})
		})

		this.$watch('hidePlayAgainButton', () => {
			this.$emit('updated', {
				hidePlayAgainButton: this.hidePlayAgainButton,
			})
		})

		this.$watch('numOfComponents', () => {
			this.onNumOfComponentsChanged()
		})
	},
	methods: {
		onTitleFieldBlur(event) {
			if (this.title != event.target.value) {
				this.title = event.target.value
				this.$emit('updated', {
					title: this.title,
				})
			}
		},
		onTimerFieldBlur(event) {
			if (this.timer != event.target.value) {
				this.timer = event.target.value
				this.$emit('updated', {
					timer: this.timer,
				})
			}
		},
		onNumOfComponentsChanged() {
			if (this.numOfComponents == 1) {
				this.page.components.right = null
			}

			this.$emit('updated', {
				components: this.page.components,
			})
		},
		onComponentUpdated(side, component) {
			this.page.components[side] = component
			// console.log('onComponentUpdated', side, component)

			this.$emit('updated', {
				components: this.page.components,
			})
		},
		togglePanel() {
			this.isExpanded = !this.isExpanded
		},
		deletePage() {
			if (!confirm('Are you sure you want to delete the page?')) {
				return
			}

			this.$emit('deleted')
		},
		moveDown() {
			this.$emit('movedDown')
		},
		moveUp() {
			this.$emit('movedUp')
		},
	},
	computed: {
		prevComponentType() {
			let componentType = this.prevPage?.components?.left?.component;

			if (componentType === 'undefined') {
				return null;
			}

			return componentType;

		}
	}
}
</script>

<style lang="less">
.card-header {
	font-size: 20px;
	font-weight: bold;
}
</style>
