<template>
    <div class="container">
        <div class="question-header">
            <h1 v-if="title">{{ title }}</h1>
            <p v-if="questionText">{{ questionText }}</p>
        </div>

        <div v-if="resultChartStyle">
            <div class="result-outer-container">
                <chart-container
                    :chart-style="resultChartStyle"
                    :options="resultOptions"
                    :results="results"
                    :unique-users="uniqueUsers"/>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from '@/components/app-button/index'
import Counter from '@/components/counter'
import Choice from '@/components/choices/choice'

import ChartContainer from '@/components/page-components/results/chart-container'

import PageComponentTemplate from '../page-component-template'
import CaseProgress from '@/components/client-components/case-progress/index'

export default {
    name: 'results-index',
    extends: PageComponentTemplate,
    components: {
        ChartContainer,
        CaseProgress,
        Choice,
        AppButton,
        Counter,
    },
    data () {
        return {
            results: [],
            resultOptions: {},
            resultChartStyle: 'bubbleChart',
            resultType: null,
            uniqueUsers: 0,

            questionText: null,
        }
    },
    mounted () {
        this.recalculate()
    },
    methods: {
        recalculate () {
            let result = this.recalculateInner()

            if (result === null) {
                this.$nextTick(() => {
                    this.recalculate()
                })
            }
        },
        recalculateInner () {
            if (!this.$gameClient) {
                console.log('1111')
                return null
            }

            if (!this.$gameClient.room) {
                console.log('2222')
                return null
            }

            if (!this.$gameClient.room.state) {
                console.log('33333')
                return null
            }

            /*if (!this.$gameClient.room.state.playerVotes) {
              console.log('4444');
              return null;
            }*/

            console.log('this.$gameClient.room.state.playerVotesJson', this.$gameClient.room.state.playerVotesJson)
            console.log('this.$gameClient.room.state.playerVotesUniqueUsersJson',
                this.$gameClient.room.state.playerVotesUniqueUsersJson)

            let playerVotes = JSON.parse(this.$gameClient.room.state.playerVotesJson)
            let playerVotesUniqueUsers = JSON.parse(this.$gameClient.room.state.playerVotesUniqueUsersJson)

            /*console.log('this.$gameClient', this.$gameClient);
            console.log('this.$gameClient.room', this.$gameClient.room);
            console.log('this.$gameClient.room.state', this.$gameClient.room.state);
            console.log('playerVotes', playerVotes);

            this.$gameClient.room.state.playerVotes.forEach((value, key) => {
              let votes = [];

              value.votes.forEach((_value, _key) => {
              votes.push(_value);
              });

              console.log('playerVotesEach', key, votes);
            });*/

            let useStandaloneResult = false
            let resultComponent, resultPageIndex, choicesComponent

            if (useStandaloneResult) {
                throw new Error('Please make the result on other page work with the new way of doing currentPage')
                resultComponent = this.getComponentOptionsFromPageIndex(this.currentPageIndex, 'Result')

                if (!resultComponent) {
                    console.log('noResultComponent')

                    return false
                }

                resultPageIndex = this.getPageIndexById(resultComponent.resultChoicePageId)

                if (resultPageIndex < 0) {
                    console.log('Result page index could not be found by id ' + resultComponent.resultChoicePageId)
                }

                /*if (!playerVotes.has(resultPageIndexString)) {
                console.log('No player votes for ' + resultPageIndexString);

                return false;
              }

              let resultPlayerVotes = playerVotes.get(resultPageIndexString).votes;*/
                choicesComponent = this.getComponentOptionsFromPageIndex(resultPageIndex, 'Choices')
            }
            else {
                choicesComponent = this.getComponentOptionsFromPage(this.currentPage, 'Choices')
                resultComponent = choicesComponent
                resultPageIndex = this.currentPage.index.toString()
            }

            let resultPageIndexString = resultPageIndex.toString()

            if (!playerVotes.hasOwnProperty(resultPageIndexString)) {
                playerVotes[resultPageIndexString] = []

                for (let choice of choicesComponent.choices) {
                    playerVotes[resultPageIndexString].push(0)
                }

                console.log('No player votes for ' + resultPageIndexString + ' - created ' + playerVotes.length +
                    ' fake 0 votes')
            }

            if (!playerVotesUniqueUsers.hasOwnProperty(resultPageIndexString)) {
                playerVotesUniqueUsers[resultPageIndexString] = 0

                console.log('No unique player vote counts for ' + resultPageIndexString + ' - set to default 0')
            }

            let resultPlayerVotes = playerVotes[resultPageIndexString]

            if (!choicesComponent) {
                console.log('noChoicesComponent')

                return false
            }

            console.log('playerVotes', playerVotes)
            console.log('resultComponet', resultComponent)
            console.log('choicesComponent', choicesComponent)
            console.log('resultPlayerVotes', resultPlayerVotes)
            console.log('playerVotesUniqueUsers', playerVotesUniqueUsers)

            let results = []

            for (let i = 0, len = choicesComponent.choices.length; i < len; i++) {
                let choice = choicesComponent.choices[i]

                let choiceMessage

                if (choice.useResultMessage && choice.resultMessage) {
                    choiceMessage = choice.resultMessage
                }
                else if (choice.message) {
                    choiceMessage = choice.message
                }
                else if (choice.text) {
                    choiceMessage = choice.text
                }
                else {
                    console.log(`Choice ${i} has no text`, choice)

                    choiceMessage = `Choice #${i + 1}`
                }

                results.push({
                    value: resultPlayerVotes[i],
                    message: choiceMessage,
                    format: choice.format,
                    //gotoPageIndex: choice.gotoPageIndex,
                })
            }

            console.log('results', choicesComponent.choices)

            this.results = results
            this.resultOptions = resultComponent
            this.resultChartStyle = resultComponent.resultStyle || 'bubbleChart'
            this.resultType = choicesComponent.resultType
            this.uniqueUsers = playerVotesUniqueUsers[resultPageIndexString] || 0

            this.questionText = choicesComponent.text
        },
        getPageIndexById (pageId) {
            for (let i = 0, len = this.currentCase.pages.length; i < len; i++) {
                let page = this.currentCase.pages[i]

                if (page.id === pageId) {
                    return i
                }
            }

            return -1
        },
        getComponentOptionsFromPageIndex (pageIndex, componentType) {
            let page = this.currentCase.pages[pageIndex]

            return this.getComponentOptionsFromPage(page, componentType)
        },
        getComponentOptionsFromPage (page, componentType) {
            if (page && page.components) {
                if (page.components.left && page.components.left.component === componentType) {
                    return page.components.left.options
                }
                else if (page.components.right && page.components.right.component === componentType) {
                    return page.components.right.options
                }
            }

            return null
        },
        pickChoice (choice) {
            this.$emit('pickChoice', choice)
        },
    },
}
</script>

<style lang="less" scoped>
.col-sm {
    &.left-column {
        padding: 0 150px;
    }
}

.counter {
    margin: 30px auto 0 auto;
}

.continue-button-container {
    .pick-choice-button {
        margin: 0 auto;
    }
}

.result-outer-container {
    background: white;
    padding: 10px;
}

.choice-container {
    margin-top: 20px;
}

.result-column {
    text-align: center;

    .pick-choice-button {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 150%);
    }
}

.your-answers-headline {
    font-weight: bold;
    font-size: 20px;
}

.question-header {
    padding: 0 125px;
}
</style>
