var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("weight-map", {
    attrs: {
      moment: _vm.moment,
      momentId: _vm.momentId,
      options: _vm.options,
      points: _vm.weightMapPlayerVotes,
      specificVoteCount: _vm.voteCount,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }