var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "question-header" }, [
      _vm.title ? _c("h1", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
      _vm.questionText ? _c("p", [_vm._v(_vm._s(_vm.questionText))]) : _vm._e(),
    ]),
    _vm.resultChartStyle
      ? _c("div", [
          _c(
            "div",
            { staticClass: "result-outer-container" },
            [
              _c("chart-container", {
                attrs: {
                  "chart-style": _vm.resultChartStyle,
                  options: _vm.resultOptions,
                  results: _vm.results,
                  "unique-users": _vm.uniqueUsers,
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }