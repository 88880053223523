<template>
	<div class="video-player">
		<video v-if="playVideo" :src="videoSource" autoplay controls></video>

		<div v-else class="video-thumbnail" @click="onClickThumbnail">
			<img :src="thumbnailSource" class="video-thumbnail-picture"/>
			<img :src="playOverlay" class="video-thumbnail-picture-overlay"/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'video-player',
	props: {
		thumbnailSource: {
			type: String,
			required: true,
		},
		videoSource: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			playVideo: false,
			playOverlay: require('./play-overlay.png'),
		}
	},
	methods: {
		onClickThumbnail() {
			this.playVideo = true
		},
	},
}
</script>

<style lang="less" scoped>
.video-player {
	position: relative;

	img.video-thumbnail-picture, video {
		width: 100%;

		min-height: 200px;

		max-height: 100%;
	}
}

.video-thumbnail {
	cursor: pointer;
}

.video-thumbnail-picture-overlay {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 150px;
	height: 150px;
	transform: translate(-50%, -50%);
}
</style>
