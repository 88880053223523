var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "question-header" }, [
      _vm.questionText
        ? _c("p", [_vm._v(_vm._s(_vm.questionText))])
        : _vm.isTeacher &&
          _vm.listType === "predefinedWithFreetext" &&
          !_vm.showResults
        ? _c("div", { staticClass: "row text-field-wrapper" }, [
            _c(
              "div",
              {
                staticClass: "text-field",
                class: _vm.freetext ? "text-field-small" : "text-field-large",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.freetext,
                      expression: "freetext",
                    },
                  ],
                  staticClass: "form-control form-freetext",
                  attrs: { placeholder: _vm.$t("misc.writeMomentPlaceholder") },
                  domProps: { value: _vm.freetext },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.freetext = $event.target.value
                    },
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "text-field-button",
                class: _vm.freetext ? "btn-large" : "btn-small",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success btn-freetext",
                    on: { click: _vm.addFreetext },
                  },
                  [_vm._v("Add")]
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "row" }, [
      _vm.instructions && _vm.instructions.length > 0
        ? _c(
            "div",
            { staticClass: "col-3" },
            _vm._l(_vm.instructions, function (instruction, i) {
              return _c(
                "div",
                { staticClass: "wireframe-space" },
                [
                  _c("TextWireBorder", {
                    attrs: {
                      color: "black",
                      text: instruction,
                      title: _vm.$t("instructions.title"),
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "div",
        {
          class:
            _vm.instructions && _vm.instructions.length > 0
              ? "col-9"
              : "col-12",
        },
        [
          _vm.isAddUserInput && _vm.choices.length === 0 && _vm.showChoices
            ? _c("p", { staticStyle: { "text-align": "center" } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("game.waitingForUserInput"))
                ),
              ])
            : _vm._e(),
          _vm.showChoices
            ? _c("choices", {
                staticClass: "choices",
                attrs: {
                  selectable: _vm.isSinglePlayer || _vm.showResults,
                  showResults: _vm.showResults,
                  voteCounts: _vm.showResults ? _vm.voteCounts : null,
                  choices: _vm.choices,
                },
                on: { pickChoice: _vm.onPickResultChoice },
              })
            : _c("div", { staticStyle: { "text-align": "center" } }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("game.waitingForInput")))]),
              ]),
        ],
        1
      ),
    ]),
    !_vm.isSinglePlayer
      ? _c(
          "div",
          { staticClass: "counter-container" },
          [
            _vm.currentPagePhase !== 1
              ? _c(
                  "counter",
                  {
                    staticClass: "counter",
                    attrs: {
                      current: _vm.studentAnswerCount,
                      total: _vm.studentCount,
                      type: "blue-thin",
                      "slot-location": "affix",
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("game.answers")) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    !_vm.isSinglePlayer
      ? _c("div", { staticClass: "clock-container" })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }