var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "results row mt-3" },
    _vm._l(_vm.results, function (result, i) {
      return _c("div", { staticClass: "col" }, [
        _c(
          "div",
          { staticClass: "result-bar-container" },
          [
            _c("result-bar", {
              attrs: {
                "fill-color": _vm.resultOptions[i].fillColor,
                percentage: Math.round(result.percentage),
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "result-bar-message-container" }, [
          _c("div", { staticClass: "result-bar-message" }, [
            _c("div", { staticClass: "result-bar-message-inner" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(result.message) +
                  "\n                "
              ),
            ]),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }