<template>
    <div class="result-bubble">
        <div v-if="showArea" :style="{ 'width': widthPx, 'height': heightPx, 'border-radius': radiusPx }"
             class="result-bubble-area"></div>
        <svg :style="{ 'width': widthPx, 'height': heightPx }" version="1.1" viewBox="0 0 215 216"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="svg-bubble-container" :fill-opacity="opacity" fill="none" fill-rule="evenodd" stroke="none"
               stroke-width="1">
                <g id="svg-bubble" :fill="fillColor" transform="translate(-224.000000, -336.000000)">
                    <g id="svg-bubble-stats" transform="translate(182.000000, 223.000000)">
                        <g id="svg-bubble-stats-inside" transform="translate(42.000000, 113.000000)">
                            <path
                                id="Oval-stat-red"
                                class="fill-to-change"
                                d="M107.529294,215.43403 C139.626929,218.54042 172.661316,204.129956 188.366933,178.723717 C202.917788,155.185484 214.772874,135.294111 214.772874,108.359027 C214.772874,89.4202002 210.054417,69.276879 196.489216,49.8955435 C174.9613,19.1374465 147.540085,-3.1947517 107.529294,1.28402367 C46.0606448,8.16477428 5.13157932,49.8955435 0.285714286,108.359027 C-1.06102125,124.606869 5.13157932,139.180038 10.1994379,153.115042 C23.5606448,189.854123 62.1320734,211.040514 107.529294,215.43403 Z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        <div :style="percentageStyle" class="result-bubble-percentage">{{ percentage }}%</div>
    </div>
</template>

<script>
import { calcFontSize, calcHeight, calcWidth } from '@/components/page-components/results/result-bubble-chart/util'

export default {
    name: 'result-bubble',
    props: {
        fillColor: {
            type: String,
            default: '#0000ff',
        },
        textColor: {
            type: String,
            default: '#ffffff',
        },
        percentage: {
            type: Number,
            default: 100,
        },
        showArea: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        width () {
            return calcWidth(this.percentage)
        },
        height () {
            return calcHeight(this.percentage)
        },
        radius () {
            return Math.max(this.width, this.height) / 2
        },
        fontSize () {
            return calcFontSize(this.percentage)
        },

        widthPx () {
            return this.width + 'px'
        },
        heightPx () {
            return this.height + 'px'
        },
        radiusPx () {
            return this.radius + 'px'
        },
        fontSizePx () {
            return this.fontSize + 'px'
        },

        percentageStyle () {
            return {
                'font-size': this.fontSizePx,
                'color': this.textColor || '#ffffff',
            }
        },
        opacity () {
            return 0.982987998
        },
    },
}
</script>

<style lang="less" scoped>

@import "~@less/variables.less";


.result-bubble-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
}

.result-bubble-area {
    border: 5px solid black;
    position: absolute;
    top: 0;
    left: 0;
}

.result-bubble {
    display: none;

    &.is-visible {
        display: block;
    }
}
</style>
