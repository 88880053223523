<template>
    <div class="result-bar">
        <div class="result-bar-inner">
            <div :style="{height: percentage + '%', background: fillColor}" class="result-bar-fill">
                <div class="result-bar-percentage">{{ percentage }}%</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'result-bar',
    props: {
        fillColor: {
            type: String,
            default: '#0000ff',
        },
        percentage: {
            type: Number,
            default: 100,
        },
    },
}
</script>

<style lang="less" scoped>

@import "~@less/variables.less";


.result-bar, .result-bar-inner {
    position: relative;
    height: 100%;
}

.result-bar-fill {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    min-height: 70px;
    border-radius: 10px;
}

.result-bar-percentage {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 22pt;
}
</style>
