<template>
	<div id="version-info">
		<span v-if="isDev">{{ version }} | Game type: {{ gameTypeString }} | Navigation guard: {{
				navigationGuardStatus
			}}</span>
		<span v-else>{{ version }}</span>
	</div>
</template>
<script>
import AppConfig from '@/app-config'
import {mapGetters} from 'vuex'
import GameType from '@/enum/GameType'

export default {
	name: 'version-info',
	computed: {
		...mapGetters({
			navigationGuardEnabled: 'getNavigationGuardEnabled',
			gameType: 'getGameType',
		}),
		isDev() {
			return AppConfig.isDev
		},
		version() {
			return this.$store.state.appVersion
		},
		gameTypeString() {
			switch (this.gameType) {
				case GameType.ClassRoom:
					return 'Class Room'
				case GameType.SinglePlayer:
					return 'Single Player'
				default:
					return '(none)'
			}
		},
		navigationGuardStatus() {
			return this.navigationGuardEnabled ? 'ENABLED' : 'DISABLED'
		},
	},
}
</script>
<style lang="less" scoped>
@import "~@less/variables.less";

#version-info {
	position: fixed;
	bottom: 0;
	left: 0;
	background: #ababab;
	color: black;
	opacity: .5;
	transition: opacity 200ms;
	padding: 2px 5px;
	font-size: 12px;
	font-family: monospace;
	border-radius: 0 5px 0 0;

	&:hover {
		opacity: 1;
	}
}
</style>
