<template>
    <div :class="anchorClass" class="result-bubble">
        <svg :style="style" version="1.1" viewBox="0 0 215 216" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="svg-bubble-container" :fill-opacity="opacity" fill="none" fill-rule="evenodd" stroke="none"
               stroke-width="1">
                <g id="svg-bubble" :fill="fillColor" transform="translate(-224.000000, -336.000000)">
                    <g id="svg-bubble-stats" transform="translate(182.000000, 223.000000)">
                        <g id="svg-bubble-stats-inside" transform="translate(42.000000, 113.000000)">
                            <path
                                id="Oval-stat-red"
                                class="fill-to-change"
                                d="M107.529294,215.43403 C139.626929,218.54042 172.661316,204.129956 188.366933,178.723717 C202.917788,155.185484 214.772874,135.294111 214.772874,108.359027 C214.772874,89.4202002 210.054417,69.276879 196.489216,49.8955435 C174.9613,19.1374465 147.540085,-3.1947517 107.529294,1.28402367 C46.0606448,8.16477428 5.13157932,49.8955435 0.285714286,108.359027 C-1.06102125,124.606869 5.13157932,139.180038 10.1994379,153.115042 C23.5606448,189.854123 62.1320734,211.040514 107.529294,215.43403 Z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        <div :style="percentageStyle" class="result-bubble-percentage">{{ percentage }}%</div>
    </div>
</template>

<script>
export default {
    name: 'result-bubble',
    props: {
        fillColor: {
            type: String,
            default: '#0000ff',
        },
        percentage: {
            type: Number,
            default: 100,
        },
        anchor: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            minSize: 65,
            minFontSize: 14,
        }
    },
    computed: {
        percentageStyle () {
            let fontSize = this.getFontSize()

            return `font-size: ${fontSize}px`
        },
        style () {
            let width = this.getWidth()
            let height = this.getHeight()

            return `width: ${width}px; height: ${height}px;`
        },
        anchorClass () {
            let classes = []
            let anchorSplit = this.anchor.split(/:/g)

            for (let anchorPart of anchorSplit) {
                classes.push('result-bubble-anchor-' + anchorPart)
            }

            return classes
        },
        opacity () {
            return 0.982987998
        },
    },
    methods: {
        calcWidth (percentage) {
            return this.calcSize(percentage, this.minSize, 215)
        },
        calcHeight (percentage) {
            return this.calcSize(percentage, this.minSize, 216)
        },
        calcFontSize (percentage) {
            return this.calcSize(percentage, this.minFontSize, 64)
        },
        calcSize (percentage, minSize, maxSize) {
            return minSize + ((maxSize - minSize) * (percentage / 100))
        },
        getWidth () {
            return this.calcWidth(this.percentage)
        },
        getHeight () {
            return this.calcHeight(this.percentage)
        },
        getFontSize () {
            return this.calcFontSize(this.percentage)
        },
    },
}
</script>

<style lang="less" scoped>

@import "~@less/variables.less";

.result-bubble-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
}

.result-bubble {
    &.result-bubble-anchor-left {
        left: 0;
    }

    &.result-bubble-anchor-center {
        left: 50%;
        transform: translateX(-50%);
    }

    &.result-bubble-anchor-right {
        right: 0;
    }

    &.result-bubble-anchor-top {
        top: 0;
    }

    &.result-bubble-anchor-middle {
        top: 50%;
        transform: translateY(-50%);
    }

    &.result-bubble-anchor-bottom {
        bottom: 0;
    }
}
</style>
