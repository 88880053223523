var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-wrapper-container" }, [
    _c(
      "div",
      { staticClass: "text-wrapper" },
      [
        _c("NotchHeader", {
          attrs: { color: "dark-blue", type: "solid", "margin-type": "small" },
        }),
        _vm.title
          ? _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
          : _vm._e(),
        _vm.text
          ? _c(
              "div",
              [
                _c("vue-markdown", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.text)),
                ]),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }