<template>
    <div class="results row mt-3">
        <div class="col text-center">
            <strong class="your-answers-headline">Your answers</strong>
            <div class="result-bubble-container">
                <result-bubble
                    v-for="(result, i) in sortedResults"
                    v-if="i < 4"
                    v-bind:key="i"
                    :anchor="resultOptions[i].anchor"
                    :fill-color="resultOptions[i].fillColor"
                    :percentage="Math.round(result.percentage)"
                    class="result-bubble"/>
            </div>

        </div>
        <div class="col">
            <div>
                <!--<app-button button-style="dark">Se Danmarks svar</app-button>-->
            </div>
            <div>
                <ul class="result-legend mt-5">
                    <li
                        v-for="(result, i) in sortedResults"
                    >
                        <span v-if="i >= 4" class="result-ball result-ball-empty"></span>
                        <span v-else :style="{ background: resultOptions[i].fillColor }" class="result-ball"></span>
                        <span class="result-legend">{{ Math.round(result.percentage) }}%: {{ result.message }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ChartTemplate from '../chart-template'
import ResultBubble from './result-bubble'
import clone from 'clone'

export default {
    extends: ChartTemplate,
    name: 'result-bubble-chart-old',
    components: {
        ResultBubble,
    },
    data () {
        let resultAnchors = [
            'center:top',
            'center:bottom',
            'left:middle',
            'right:middle',

            null,
            null,
            null,
            null,
        ]

        let resultFillColors = [
            '#da291c', // 0
            '#009ca6', // 1
            '#f08447', // 2
            '#f2a900', // 3
            '#ffffff', // 4
            '#ffffff', // 5
            '#ffffff', // 6
            '#ffffff', // 7
        ]

        let resultOptions = []

        for (let i = 0, len = resultAnchors.length; i < len; i++) {
            resultOptions.push({
                fillColor: resultFillColors[i],
                anchor: resultAnchors[i],
            })
        }

        return {
            sortedResults: [],
            resultOptions: resultOptions,
        }
    },
    mounted () {
        this.recalculateSortedResults()
    },
    watch: {
        results () {
            this.recalculateSortedResults()
        },
    },
    methods: {
        recalculateSortedResults () {
            let sortedResults = clone(this.results)

            sortedResults.sort(function (a, b) {
                if (a.percentage < b.percentage) {
                    return 1
                }
                else if (a.percentage > b.percentage) {
                    return -1
                }
                else {
                    return 0
                }
            })

            this.sortedResults = sortedResults
        },
        onOvalLoad (e) {
            //console.log('this', this, e);
        },
    },
}
</script>

<style lang="less" scoped>
.result-bubble-container {
    position: relative;
    width: 100%;
    height: 300px;

    .result-bubble {
        position: absolute;
    }
}

ul.result-legend {
    list-style-type: none;
    font-size: 20px;
    margin-top: 10px;

    li {
        padding-left: 0;
    }

    .result-ball {
        width: 20px;
        height: 20px;
        display: inline-block;
        border-radius: 10px;
        margin-right: 10px;
        position: relative;
        top: 3px;

        &.result-ball-empty {
            border: 1px solid black;
            background: white;
        }
    }
}
</style>
