const minSize = 65
const minFontSize = 14

export function calcSize (percentage, minSize, maxSize) {
    return minSize + ((maxSize - minSize) * (percentage / 100))
}

export function calcWidth (percentage) {
    return calcSize(percentage, minSize, 215)
}

export function calcHeight (percentage) {
    return calcSize(percentage, minSize, 216)
}

export function calcFontSize (percentage) {
    return calcSize(percentage, minFontSize, 64)
}