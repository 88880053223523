var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result-bubble", class: _vm.anchorClass }, [
    _c(
      "svg",
      {
        style: _vm.style,
        attrs: {
          version: "1.1",
          viewBox: "0 0 215 216",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
        },
      },
      [
        _c(
          "g",
          {
            attrs: {
              id: "svg-bubble-container",
              "fill-opacity": _vm.opacity,
              fill: "none",
              "fill-rule": "evenodd",
              stroke: "none",
              "stroke-width": "1",
            },
          },
          [
            _c(
              "g",
              {
                attrs: {
                  id: "svg-bubble",
                  fill: _vm.fillColor,
                  transform: "translate(-224.000000, -336.000000)",
                },
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      id: "svg-bubble-stats",
                      transform: "translate(182.000000, 223.000000)",
                    },
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "svg-bubble-stats-inside",
                          transform: "translate(42.000000, 113.000000)",
                        },
                      },
                      [
                        _c("path", {
                          staticClass: "fill-to-change",
                          attrs: {
                            id: "Oval-stat-red",
                            d: "M107.529294,215.43403 C139.626929,218.54042 172.661316,204.129956 188.366933,178.723717 C202.917788,155.185484 214.772874,135.294111 214.772874,108.359027 C214.772874,89.4202002 210.054417,69.276879 196.489216,49.8955435 C174.9613,19.1374465 147.540085,-3.1947517 107.529294,1.28402367 C46.0606448,8.16477428 5.13157932,49.8955435 0.285714286,108.359027 C-1.06102125,124.606869 5.13157932,139.180038 10.1994379,153.115042 C23.5606448,189.854123 62.1320734,211.040514 107.529294,215.43403 Z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "result-bubble-percentage", style: _vm.percentageStyle },
      [_vm._v(_vm._s(_vm.percentage) + "%")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }