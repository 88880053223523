<template>
	<div :style="containerStyle" class="qr-code">
	</div>
</template>

<script>
import QRCode from 'qrcode'

export default {
	name: 'qr-code',
	props: {
		value: {
			type: String,
		},
		align: {
			type: String,
			default: 'center',
		},
		version: {
			type: Number,
			default: null,
		},
		errorCorrectionLevel: {
			type: String,
			default: 'M',
		},
		maskPattern: {
			type: Number,
			default: null,
		},
		toSJISFunc: {
			type: Function,
			default: null,
		},
		margin: {
			type: Number,
			default: 1,
		},
		scale: {
			type: Number,
			default: 3.5,
		},
		width: {
			type: Number,
			default: null,
		},
		colorDark: {
			type: String,
			default: '#000000ff',
		},
		colorLight: {
			type: String,
			default: '#ffffffff',
		},
	},
	data() {
		return {
			qrCodeData: null,
		}
	},
	mounted() {
		this.generateQrCodeFromValue()
	},
	computed: {
		containerStyle() {
			let style = {
				'text-align': this.align,
				'background-image': 'url(' + this.qrCodeData + ')',
			}

			if (this.width) {
				style.width = this.width + 'px'
				style.height = this.height + 'px'
			}

			return style
		},
	},
	watch: {
		$data: {
			handler: function (val, oldVal) {
				this.generateQrCodeFromValue()
			},
			deep: true,
		},
	},
	methods: {
		generateQrCodeFromValue() {
			let options = {
				'version': this.version,
				'errorCorrectionLevel': this.errorCorrectionLevel,
				'maskPattern': this.maskPattern,
				'toSJISFunc': this.toSJISFunc,
				'margin': this.margin,
				'scale': this.scale,
				'width': this.width,
				'color.dark': this.colorDark,
				'color.light': this.colorLight,
			}

			if (options.width && options.width <= -1) {
				delete options.width
			}

			if (options.scale && options.scale <= -1) {
				delete options.scale
			}

			if (options.version && options.version <= -1) {
				delete options.version
			}

			console.log('QRCode options', this.value, options)

			QRCode.toDataURL(this.value, options).then((url) => {
				this.qrCodeData = url
			}).catch((err) => {
				console.error(`Could not generate QR code from value "${value}": ${err}`)
			})
		},
	},
}
</script>
<style lang="less">
.qr-code {
	background-position: top center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	min-width: 60px;
	min-height: 60px;
}
</style>
