var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "version-info" } }, [
    _vm.isDev
      ? _c("span", [
          _vm._v(
            _vm._s(_vm.version) +
              " | Game type: " +
              _vm._s(_vm.gameTypeString) +
              " | Navigation guard: " +
              _vm._s(_vm.navigationGuardStatus)
          ),
        ])
      : _c("span", [_vm._v(_vm._s(_vm.version))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }