<template>
    <div class="interactive-weight-map-container">
        <div class="background-color"></div>
        <div v-if="interactive" class="interactive-weight-map">
            <div class="header">
                <div v-if="momentId" class="text-wrapper">
                    <div class="moment-id">#{{ this.momentIdFormatted }}</div>
                    <div class="moment">{{ this.moment }}</div>
                </div>

                <div class="place-token-start-wrapper">
                    <img
                        v-if="(-1) === point.y && (-1) === point.x"
                        class="btn-point-new btn-sm ml-2"
                        src="~@img/misc/token.png"
                    />
                </div>

                <div class="place-token-text">{{ $t('instructions.weightBoard2D') }}</div>
            </div>
            <div ref="board2D_wrapper" class="board2D-wrapper" v-on:mouseup="clickBoard">
                <img
                    v-for="listPoint of points"
                    v-if="(listPoint.top) === point.y && (listPoint.left) === point.x"
                    :style="{ top: `${listPoint.top }%`, left: `${listPoint.left}%` }"
                    class="btn-point-new btn-point-board btn-sm ml-2"
                    src="~@img/misc/token.png"
                />
                <div v-for="listPoint of points"
                     v-if="!((listPoint.top) === point.y && (listPoint.left) === point.x)"
                     :style="{ top: `${listPoint.top }%`, left: `${listPoint.left}%` }"
                     class="placeholder-tokens"
                />

                <!--<button
                    v-for="listPoint of points"
                    class="btn-point-new btn-sm ml-2"
                    :class="(listPoint.top) === point.y && (listPoint.left) === point.x ? 'btn-token' : 'btn-no-token'"
                    :style="{ top: `${listPoint.top}%`, left: `${listPoint.left}%` }"
                />-->

                <!-- Yes, horizontal image is used for vertical and vertical used for horizontal -->
                <!-- I know it's confusing -->
                <img v-if="boardType === BoardType.Vertical" ref="board2D" class="board2D"
                     :src="boardImages.horizontal"/>
                <img v-else-if="boardType === BoardType.Horizontal" ref="board2D" class="board2D"
                     :src="boardImages.vertical"/>
                <img v-else ref="board2D" class="board2D" :src="boardImages.full"/>

            </div>
            <div class="button-wrapper">
                <app-button-fit
                    :enabled="this.isPointChosen"
                    class="py-4"
                    v-on:onClick="submitPoint"
                >{{ $t('game.ready') }}
                </app-button-fit>
            </div>


            <!-- Show the interactive weight map here - so the 2d version that you can click on -->
        </div>
        <!--<weight-map :points="[point]" v-else />-->
        <weight-map v-else :points="[point]"/>
    </div>
</template>
<script>
import WeightMap from './weight-map'
import AppButtonFit from '@/components/app-button-fit'
import TokenType from '../../../enum/TokenType'
import BoardType from '../../../enum/BoardType'
import { mapGetters } from 'vuex'

// <!--@click="emitPoint(listPoint.top, listPoint.left)"-->

// Not used
const boardImageHorizontal = require('@img/misc/board_2d_horizontal.png');
const boardImageVertical = require('@img/misc/board_2d_vertical.png');

const boardImageAssets = {
    cn: {
        horizontal: boardImageHorizontal,
        vertical: boardImageVertical,
        full: require(`@img/localized-assets/cn/board-full.png`),
    },
    cs: {
        horizontal: boardImageHorizontal,
        vertical: boardImageVertical,
        full: require(`@img/localized-assets/cs/board-full.png`),
    },
    da: {
        horizontal: boardImageHorizontal,
        vertical: boardImageVertical,
        full: require(`@img/localized-assets/da/board-full.png`),
    },
    de: {
        horizontal: boardImageHorizontal,
        vertical: boardImageVertical,
        full: require(`@img/localized-assets/de/board-full.png`),
    },
    en: {
        horizontal: boardImageHorizontal,
        vertical: boardImageVertical,
        full: require(`@img/localized-assets/en/board-full.png`),
    },
    es: {
        horizontal: boardImageHorizontal,
        vertical: boardImageVertical,
        full: require(`@img/localized-assets/es/board-full.png`),
    },
    fr: {
        horizontal: boardImageHorizontal,
        vertical: boardImageVertical,
        full: require(`@img/localized-assets/fr/board-full.png`),
    },
    pt: {
        horizontal: boardImageHorizontal,
        vertical: boardImageVertical,
        full: require(`@img/localized-assets/pt/board-full.png`),
    },
}

export default {
    components: {
        WeightMap,
        AppButtonFit,
    },
    props: {
        interactive: {
            type: Boolean,
            required: false,
            default: true,
        },
        isFirst: {
            type: Boolean,
            required: true,
        },
        momentType: {
            type: String,
            required: false,
            default: 'moment',
        },
        momentsHiddenInitially: {
            type: Boolean,
            required: false,
            default: false,
        },
        tokenType: {
            type: String,
            required: false,
            default: 'bothSameTime',
        },
    },
    data () {
        const pointsHorizontal = [
            { top: 10, left: 50 },
            { top: 25, left: 50 },
            { top: 40, left: 50 },
            { top: 60, left: 50 },
            { top: 75, left: 50 },
            { top: 90, left: 50 },
        ]

        const pointsVertical = [
            { top: 50, left: 10 },
            { top: 50, left: 25 },
            { top: 50, left: 40 },
            { top: 50, left: 60 },
            { top: 50, left: 75 },
            { top: 50, left: 90 },
        ]

        let pointsBoth = []

        pointsHorizontal.forEach(value => pointsBoth.push(value))
        pointsVertical.forEach(value => pointsBoth.push(value))

        return {
            pointsHorizontal: pointsHorizontal,
            pointsVertical: pointsVertical,
            pointsBoth: pointsBoth,
            point: {
                x: -1,
                y: -1,
            },
            windowTop: 0,
            BoardType: BoardType,
        }
    },
    mounted () {
        window.addEventListener('scroll', this.onScroll, true)
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.onScroll, true)
    },
    emits: ['pointChanged'],
    methods: {
        onScroll (e) {
            this.windowTop = e.target.scrollTop
        },
        emitPoint (x, y) {
            this.$emit('pointChanged', {
                x: x,
                y: y,
            })
        },
        submitPoint () {
            console.log('inside submit', this.isPointChosen)
            if (this.isPointChosen) {
                this.$emit('submit', this.point)
            }
        },
        clickBoard (event) {
            console.log('clicking the board')

            let boardHeight = this.$refs.board2D_wrapper.clientHeight
            let boardTop = this.$refs.board2D_wrapper.offsetTop

            let boardTopMargin = this.$refs.board2D.offsetTop
            let boardLeft = this.$refs.board2D_wrapper.offsetLeft - boardHeight / 2 // because of the transform

            let mouseX = event.pageX
            let mouseY = event.pageY

            let selectedPoint
            let distanceToPoint = 999

            let counter = 0
            for (let point of this.points) {
                // get mouse pos in percentage of image
                let mouseXPercentage = (mouseX - boardLeft) / boardHeight * 100
                let mouseYPercentage = (mouseY - boardTop - boardTopMargin + this.windowTop) / boardHeight * 100

                // calculate distance
                let tempDistanceToPoint = Math.sqrt(
                    Math.pow(mouseXPercentage - point.left, 2) + Math.pow(mouseYPercentage - point.top, 2))

                if (tempDistanceToPoint < distanceToPoint) {
                    selectedPoint = counter
                    distanceToPoint = tempDistanceToPoint
                }

                counter++
            }

            this.point = {
                x: this.points[selectedPoint].left,
                y: this.points[selectedPoint].top,
            }
        },
    },
    computed: {
        ...mapGetters({
            language: 'getLanguage',
        }),
        boardImages() {
            let boardImageAssetsLocalized;

            if (this.language) {
                boardImageAssetsLocalized = boardImageAssets[this.language];
            } else {
                boardImageAssetsLocalized = boardImageAssets.en;
            }

            console.log('boardImages', this.language, boardImageAssetsLocalized);

            return boardImageAssetsLocalized
        },
        isPointChosen () {
            return !((-1) === this.point.y && (-1) === this.point.x)
        },
        momentIdFormatted () {
            return this.momentId + 1
        },
        momentId () {
            if (this.$gameClient.room.state.selectedChoiceIndex) {
                let momentID = JSON.parse(this.$gameClient.room.state.selectedChoiceIndex)
                return momentID
            }
            return null

            //let playerVotes = JSON.parse(this.$gameClient.room.state.playerVotesJson)
            //let playerVotesUniqueUsers = JSON.parse(this.$gameClient.room.state.playerVotesUniqueUsersJson)
        },
        moment () {
            let choices = this.$gameClient.room.state.current_case_page.component_left.options.choices
            if (this.momentId && choices[this.momentId] && choices[this.momentId].message) {
                let currentMoment = choices[this.momentId].message
                return currentMoment
            }
            return null

        },
        points () {
            const boardType = this.boardType

            if (boardType === BoardType.Horizontal) {
                return this.pointsHorizontal
            }
            else if (boardType === BoardType.Vertical) {
                return this.pointsVertical
            }

            return this.pointsBoth
        },
        boardType () {
            if (this.tokenType === TokenType.BothSameTime) {
                return 'both'
            }
            else if (this.tokenType === TokenType.Horizontal) {
                return BoardType.Horizontal
            }
            else if (this.tokenType === TokenType.Vertical) {
                return BoardType.Vertical
            }

            return this.isFirst ? BoardType.Horizontal : BoardType.Vertical
        },
    },
}
</script>

<style lang="less" scoped>
@import "~@less/variables.less";

.header {
    width: 100%;


    .place-token-text {
        margin: 10px;
        opacity: 0.3;
        text-align: center;
        text-align-last: center;
        font-family: 'Kalam', cursive;
    }
}

.btn.btn-point {
    width: 50px;

}

.btn-point-new {
    opacity: 0.8;
    position: absolute;
    z-index: 2;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    stroke: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;

    /* This stops dragging in chrome */
    -webkit-user-drag: none;
}

.btn-point-board {
    width: calc(20vw);
}


.interactive-weight-map-container {
    width: 100%;
    height: 100%;
    padding-top: 30px;
    position: relative;
    background-color: #DDDDDD;

    .background-color {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #DDDDDD;
        z-index: -1;
    }

    .interactive-weight-map {
        margin-left: auto;
        margin-right: auto;
        z-index: 3;

        .text-wrapper {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            text-align: left;

            .moment {
                font-weight: 500;
                font-size: @font-size-xl;
                margin-bottom: 15px;
            }

            .moment-id {
                opacity: 0.4;
                left: 0;
                font-size: @font-size-xl;
                font-family: 'Catamaran', sans-serif;
            }
        }

        .place-token-start-wrapper {
            background-color: #D3D3D3;
            height: calc(20vw);
            width: calc(20vw);
            border-radius: calc(10vw);
            margin-right: auto;
            margin-left: auto;
            z-index: 3;

            img {
                top: 50%;
                left: 50%;
                position: relative;
                width: 100%;
            }
        }

    }

    .button-wrapper {
        width: 70%;
        margin-right: auto;
        margin-left: auto;
    }
}

.btn-outline {
    height: calc(10vw);
    width: calc(10vw);
    stroke: none;
    border: none;
}

.btn-no-token {
    height: calc(16vw);
    width: calc(16vw);
    background: none;
    stroke: none;
    border: none;
}

.btn-token {
    background-color: #6E90E8;
    height: calc(10vw);
    width: calc(10vw);
    stroke: none;
    border: none;
    opacity: 0.8;
    box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0.17);


}

.placeholder-tokens {
    background-image: url("../../../_assets/img/misc/dropzone.png");
    height: calc(15vw);
    width: calc(15vw);
    position: absolute;
    background-size: calc(14vw);
    background-repeat: no-repeat;
    background-position: center center;

    opacity: 1;
    z-index: 2;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    stroke: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;

}

.board2D-wrapper {
    width: 80%;
    position: relative;
    height: calc(80vw);
    left: 50%;
    transform: translate(-50%, 0);
}

.board2D {
    width: 100%;
    position: absolute;
    z-index: 1;
    margin-top: calc(1vw);
    -webkit-box-shadow: 0px 4px 22px 3px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 22px 3px rgba(0, 0, 0, 0.25);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;

    /* This stops dragging in chrome */
    -webkit-user-drag: none;
}


</style>
