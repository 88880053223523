<template>
    <!--<div>Input {{selectedChoiceIndex}}</div>-->
    <div>
        <weight-map :isFirst="isFirst" :moment="moment" :momentId="momentId" :momentType="momentType" :momentsHiddenInitially="momentsHiddenInitially"
                    :options="options" :points="isFirst ? [] : weightMapPlayerVotes" :specificVoteCount="voteCount"/>

        <div class="counter-container">
            <counter :current="studentAnswerCount" :type="'blue-thin'" class="counter" slot-location="affix">
                {{ $t('game.answers') }}
            </counter>
        </div>
    </div>

</template>

<script>
import PageComponentTemplate from '../page-component-template'
import WeightMap from './weight-map'
import Counter from '@/components/counter'

export default {
    name: 'page-component-weight-map-input',
    components: { WeightMap, Counter },
    extends: PageComponentTemplate,
    props: {
        selectedChoiceIndex: {
            type: Number,
            default: -1,
        },
        isFirst: {
            type: Boolean,
            required: false,
            default: true,
        },
        momentType: {
            type: String,
            required: false,
            default: 'moment',
        },
        momentsHiddenInitially: {
            type: Boolean,
            required: false,
            default: false,
        },
        options: {
            type: Object,
            required: false,
            default () {
                return {}
            },
        },
    },
    computed: {
        roomState () {
            return this.$gameClient?.room?.state || null;
        },
        momentId () {
            if (!this.roomState) {
                return;
            }

            let selectedChoiceIndex = this.roomState.selectedChoiceIndex;

            if (selectedChoiceIndex === null || selectedChoiceIndex === false && typeof(selectedChoiceIndex) === 'undefined') {
                return null;
            }

            if (selectedChoiceIndex < 0) {
                return null;
            }

            return selectedChoiceIndex;
        },
        moment () {
            let choices = this.currentPage?.component_left?.options?.choices || [];

            console.log('choices', this.momentId, choices.length);

            console.log({
                '1': this.currentPage,
                '2': this.currentPage?.component_left,
                '3': this.currentPage?.component_left?.options?.choices,
                '4': this.currentPage?.component_left?.options?.choices
            });

            if (this.momentId === null) {
                return null; // moment id could not be found yet
            }

            if (this.momentId >= choices.length) {
                return null; // not enough choices to have this moment id
            }

            let moment = choices[this.momentId];

            return moment.message || null;
        },
        voteCount () {
            let voteCount = this.roomState?.voteCounts[this.momentId]
            return voteCount
        },
        studentAnswerCount () {
            // Add up all the votes to get the total count
            //console.log(this.voteCounts, "votecounts", this.roomState.uniqueVoteCounts);
            //return this.voteCounts.reduce((a, b) => a + b, 0)
            return this.roomState?.uniqueWeightMapVoteCounts || 0
        },
        weightMapPlayerVotes () {
            return []
            // return this.roomState ? this.roomState.weightMapPlayerVotes : [];
        },
    },
}
</script>

<style lang="less" scoped>
.counter-container {
    position: fixed;
    bottom: 85px;
    left: 80px;
    z-index: 999999;
}
</style>
