var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-wrapper" },
    [
      _c("NotchHeader", { attrs: { color: "light-blue", type: "wire" } }),
      _vm.title
        ? _c(
            "h1",
            { staticClass: "title", class: ["title-color-" + _vm.color] },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
      _c(
        "div",
        { class: "text-color-" + _vm.color },
        [_vm.text ? _c("vue-markdown", [_vm._v(_vm._s(_vm.text))]) : _vm._e()],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }