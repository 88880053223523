<template>
    <div class="text-wrapper">
        <NotchHeader :color="'light-blue'" :type="'wire'"></NotchHeader>
        <h1 v-if="title" :class="['title-color-' + color]" class="title">{{ title }}</h1>
        <div :class="'text-color-' + color">
            <vue-markdown v-if="text">{{ text }}</vue-markdown>
        </div>
    </div>
</template>

<script>
import NotchHeader from '@/components/notch-header'
import VueMarkdown from 'vue-markdown'

export default {
    name: 'page-text-wire-border',
    props: {
        title: {
            type: String,
            required: false,
        },
        text: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: 'white',
        },
    },
    components: {
        NotchHeader,
        VueMarkdown,
    },
}
</script>

<style lang="less" scoped>
@import "~@less/variables.less";
@import "~@less/font-sizes.less";


.text-wrapper {
    border-style: none solid solid solid;
    border-color: #BFD2E1;
    border-width: @notch-border-width;
    width: 100%;
    position: relative;
    padding: 10px 15px 15px 15px;
}

.text {
    font-weight: 500;
    opacity: 0.8;
    font-size: @font-size-sm * 0.9;
}

.text-color-black {
    color: #303030;
    font-weight: 400;
    font-size: @font-size-sm * 0.9;
}

.text-color-white {
    color: @color-white;
    font-weight: 400;
    font-size: @font-size-sm * 0.9;
}

.title {
    font-weight: 500;
    font-size: @font-size-md * 0.9;
    margin-bottom: 15px;
}

.title-color-black {
    color: @color-purple;
}

.title-color-white {
    color: @color-light-grey;
}

.page-component-text {
    &.side-full {
        padding: 0 calc(15vw);
    }
}
</style>
