var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.title ? _c("h1", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
      _vm.text
        ? _c("vue-markdown", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.text)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }