<template>
    <div :class="'page-component-' + (component || 'unknown').toLowerCase()" class="page-component-wrapper">
        <div class="page-component">
            <component
                :is="'PageComponent' + component"
                :class="'page-component-' + componentLowerCase + ' side-' + side"
                :options="options"
                :show-results="showResults"
                :side="side"
                :title="title"
                :dynamic-options="dynamicOptions"
                class="page-component"
                v-on:pickChoice="onPickChoice"
            />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

import PageComponentChoices from './choices'
import PageComponentMedia from './media'
import PageComponentBackgroundImage from './background-image'
import PageComponentText from './text'
import PageComponentResults from './results'
import PageComponentWeightMapInput from './weight-map/weight-map-input'
import PageComponentWeightMapOutput from './weight-map/weight-map-output'
import PageComponentDarkBox from './dark-box'
import PageComponentWhiteBox from './white-box'

import AppButton from '@/components/app-button'
import VueMarkdown from 'vue-markdown'

export default {
    name: 'page-component',
    components: {
        PageComponentChoices,
        PageComponentMedia,
        PageComponentText,
        PageComponentResults,
        PageComponentBackgroundImage,
        PageComponentWeightMapInput,
        PageComponentWeightMapOutput,
        AppButton,
        VueMarkdown,
		PageComponentDarkBox,
		PageComponentWhiteBox,
    },
    props: {
        side: {
            type: String,
            required: true,
        },
        component: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
        options: {
            type: Object,
            required: false,
            default () {
                return {}
            },
        },
        showResults: {
            type: Boolean,
            default: false,
        },
        dynamicOptions: {
            type: Object,
            required: false,
            default () {
                return {}
            },
        },
    },
    computed: {
        ...mapGetters({
            currentCase: 'getCurrentCase',
        }),

        componentLowerCase () {
            return this.component ? this.component.toLowerCase() : null
        },
        showTitle () {
            return this.isLeftOrFull && this.title
        },
        isLeftOrFull () {
            return this.side === 'left' || this.side === 'full'
        },
        text () {
            if (this.component === 'Results') {
                return false
            }

            return this.options && this.options.text ? this.options.text : ''
        },
    },
    methods: {
        onPickChoice (choice) {
            this.$emit('pickChoice', choice)
        },
    },
}
</script>

<style lang="less">
.page-component-wrapper.page-component-backgroundimage {
    height: 100%;

    > .page-component {
        height: 100%;

        > .bh-image-container {
            height: 100%;
        }
    }
}
</style>
