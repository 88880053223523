<template>
    <div>
        <h1 v-if="title">{{ title }}</h1>

        <vue-markdown v-if="text" class="text">{{ text }}</vue-markdown>
    </div>
</template>

<script>
import PageComponentTemplate from '../page-component-template'
import VueMarkdown from 'vue-markdown'

export default {
    name: 'page-component-text',
    extends: PageComponentTemplate,
    components: {
        VueMarkdown,
    },
    computed: {
        text () {
            if (!this.options) {
                return
            }

            if (!this.options.text) {
                return
            }

            return this.options.text
        },
    },
}
</script>

<style lang="less" scoped>

.text {
    font-weight: 500;
}

.page-component-text {
    &.side-full {
        padding: 0 calc(15vw);
    }
}
</style>
