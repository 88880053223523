<template>
    <div :style="containerStyle" class="bg-image-container">
    </div>
</template>

<script>
import MediaManager from '@/media-manager'
import PageComponentTemplate from '../page-component-template'

export default {
    name: 'page-component-background-image',
    extends: PageComponentTemplate,
    props: {
        backgroundSize: {
            type: String,
            default: 'cover',
        },
    },
    computed: {
        containerStyle () {
            return {
                'background-image': `url("${this.imageSource}")`,
                'background-size': this.backgroundSize,
            }
        },
        imageSource () {
            console.log(this.options, this.options.url, 'imageSource')
            return MediaManager.getMediaUrl(this.options.url)
        },
    },
}
</script>

<style lang="less" scoped>
@import "~@less/font-sizes.less";

.bg-image-container {
    background-repeat: no-repeat;
    height: 100%;
}
</style>
