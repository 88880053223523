<template>
    <div>
        <div class="question-header">
            <p v-if="questionText">{{ questionText }}</p>


            <div v-else-if="(isTeacher && listType === 'predefinedWithFreetext') && !showResults"
                 class="row text-field-wrapper">
                <div :class="freetext ? 'text-field-small' : 'text-field-large'" class="text-field"><input
                    v-model="freetext" :placeholder="$t('misc.writeMomentPlaceholder')"
                    class="form-control form-freetext"/>
                </div>
                <div :class="freetext ? 'btn-large' : 'btn-small'" class="text-field-button">
                    <button class="btn btn-success btn-freetext" @click="addFreetext">Add</button>
                </div>
            </div>
        </div>

        <div class="row">
            <div v-if="instructions && instructions.length > 0" class="col-3">
                <!--<h1>{{ instruction }}</h1>-->
                <div v-for="(instruction, i) in instructions" class="wireframe-space">
                    <TextWireBorder :color="'black'" :text="instruction"
                                    :title="$t('instructions.title')"></TextWireBorder>

                </div>

            </div>
            <div :class="instructions && instructions.length > 0 ? 'col-9' : 'col-12'">
                <p v-if="isAddUserInput && choices.length === 0 && showChoices" style="text-align: center">
                    {{ $t('game.waitingForUserInput') }}</p>
                <choices
                    v-if="showChoices"
                    :selectable="isSinglePlayer || showResults"
                    :showResults="showResults"
                    :voteCounts="showResults ? voteCounts : null"
                    class="choices"
                    v-bind:choices="choices"
                    v-on:pickChoice="onPickResultChoice"
                />
                <div v-else style="text-align: center">
                  <p>{{ $t('game.waitingForInput') }}</p>
                </div>
            </div>

        </div>


        <div v-if="!isSinglePlayer" class="counter-container">
            <counter :current="studentAnswerCount" :total="studentCount" :type="'blue-thin'" v-if="currentPagePhase !== 1" class="counter"
                     slot-location="affix">
                {{ $t('game.answers') }}
            </counter>
        </div>

        <div v-if="!isSinglePlayer" class="clock-container">
        </div>
    </div>
</template>
<script>
import PageComponentTemplate from '../page-component-template'
import Counter from '@/components/counter'
import Choices from '@/components/choices'
import { mapGetters } from 'vuex'
import GameType from '@/enum/GameType'
import TextWireBorder from '@/components/page-components/text-wire-border'
import PagePhase from '../../../enum/PagePhase'

export default {
    name: 'page-component-full-choices',
    extends: PageComponentTemplate,
    props: {
        showResults: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Counter,
        Choices,
        TextWireBorder,
    },
    data () {
        return {
            freetext: '',
        }
    },
    destroyed () {
        if (this.isTeacher) {
            let mostPopularChoice = 0
            let mostPopularChoiceValue = this.voteCounts[0]
            for (let i = 1; i < this.voteCounts.length; i++) {
                if (this.voteCounts[i] > mostPopularChoiceValue) {
                    mostPopularChoice = i
                    mostPopularChoiceValue = this.voteCounts[i]
                }
            }

            if (mostPopularChoiceValue > 0) {
                const choice = this.choices[mostPopularChoice]
                this.$gameClient.send({
                    _changeGlobalScore: choice.globalScore || 0,
                })
            }
        }
    },
    methods: {
        addFreetext () {
            if (this.freetext) {
                this.$gameClient.send({ '_addChoice': this.freetext })

                this.freetext = ''
            }
        },
        onPickResultChoice (choice) {
            this.$emit('pickChoice', choice)
        },
    },
    computed: {
        ...mapGetters({
            gameType: 'getGameType',
            currentPagePhase: 'getCurrentPagePhase',
        }),
        showChoices() {
            let momentsHiddenInitially = this.options?.momentsHiddenInitially;
            let showHiddenMoments = this.dynamicOptions?.showHiddenMoments;

            if (typeof(momentsHiddenInitially) === 'undefined') {
                momentsHiddenInitially = false;
            }

            if (typeof(showHiddenMoments) === 'undefined') {
                showHiddenMoments = true;
            }
            console.log({
                momentsHiddenInitially:momentsHiddenInitially,
                showHiddenMoments:showHiddenMoments,
            }, this.choices)

            return momentsHiddenInitially ? showHiddenMoments : true;
        },
        isAddUserInput () {
            return this.currentPagePhase === PagePhase.Normal
        },
        questionText () {
            return (this.options && this.options.text) ? this.options.text : null
        },
        isSinglePlayer () {
            return this.gameType === GameType.SinglePlayer
        },
        choiceType () {
            if (this.options && this.options.choiceType) {
                return this.options.choiceType
            }

            return null
        },
        listType () {
            if (this.options && this.options.listType) {
                return this.options.listType
            }

            return null
        },
        resultType () {
            if (this.options && this.options.resultType) {
                return this.options.resultType
            }

            return null
        },
        momentType () {
            if (this.options && this.options.momentType) {
                return this.options.momentType
            }

            return null
        },
        momentsHiddenInitially () {
            return this.options?.momentsHiddenInitially || false;
        },
        instructions () {
            if (this.options.instructions) {
                if (this.options.instructions[this.currentPagePhase].instructions) {
                    return this.options.instructions[this.currentPagePhase].instructions
                }

            }
            return []

        },
        choices () {
            if (this.options && this.options.hasOwnProperty('choices')) {
                return this.options.choices
            }

            return []
        },
        roomState () {
            if (this.$gameClient && this.$gameClient.room && this.$gameClient.room.state) {
                return this.$gameClient.room.state
            }

            return null
        },
        studentCount () {
            return this.roomState ? this.roomState.studentCount : 0
        },
        voteCounts () {
            return this.roomState ? this.roomState.voteCounts : [0, 0, 0, 0, 0, 0]
        },
        studentAnswerCount () {
            return this.roomState ? this.roomState.uniqueVoteCounts : 0
        },
    },
}
</script>
<style lang="less" scoped>
.counter-container {
    position: fixed;
    bottom: 85px;
    left: 80px;
    z-index: 999999;
}

.wireframe-space {
    margin-top: 15px;
    margin-bottom: 30px;
    transform: translate(65px, 0px);
}

.text-field-wrapper {
    margin-right: 0;
    margin-left: 0;

    .text-field {
        transition-property: width;
        transition-duration: 300ms;
    }

    .text-field-button {
        transition-property: width, opacity, padding-left;
        transition-duration: 300ms;

    }

    .text-field-small {
        width: 83%;
    }

    .text-field-large {
        width: 100%;
    }

    .btn-small {
        width: 0;
        opacity: 0;
    }

    .btn-large {
        width: 17%;
        opacity: 1;
        margin-right: 0;
        padding-right: 0;
        padding-left: 15px;
    }

    .btn-freetext {
        width: 100%;
    }


    .form-freetext {
        margin-bottom: 10px;
        width: 100%;
        border: none;
        border-radius: 0;
    }
}


.question-header {
    padding: 0 125px;
}
</style>
