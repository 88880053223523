<template>
	<div v-if="type==='solid'" class="notch-container solid" :class="['margin-type-' + marginType]">
		<div :class="['color-' + color]" class="first-square notch"/>
		<div :class="['color-triangle-right-' + color]" class="notch-triangle notch-right"/>
		<div :class="['color-triangle-left-' + color]" class="notch-triangle notch-left"/>
		<div :class="['color-' + color]" class="last-square notch"/>
	</div>
	<div v-else-if="type==='wire'" class="notch-container-wire" :class="['margin-type-' + marginType]">
		<div :class="['color-wire-' + color]" class="first-square-wire notch wire-style"/>
		<div :class="['color-wire-' + color]" class="wire-notch-triangle wire-style"/>
		<div :class="['color-wire-' + color]" class="last-square-wire notch wire-style"/>
	</div>
</template>

<script>
export default {
	name: 'app-button',
	props: {
		type: {
			type: String,
			default: 'solid',
		},
		color: {
			type: String,
			default: 'white',
		},
		marginType: {
			type: String,
			default: 'none',
		},
	},
}
</script>
<style lang="less" scoped>
@import "~@less/font-sizes.less";
@import "~@less/mixins/mixins.less";
@import "~@less/variables.less";


@app-button-width: 190px;
@app-button-height: 58px;
@3d-effect-size: 4px;
@transition: all 0.2s ease-in-out;

@color-notch-white: @color-white;

.notch-container {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;

	.notch-triangle {
		position: absolute;
		top: 0;
		width: 0;
		height: 0;
		border-style: solid;
	}

	.notch-right {
		transform: translate(0, -100%);
		border-width: 0 0 15px 15px;
		left: 65px;
	}

	.notch-left {
		transform: translate(0, -100%);
		border-width: 15px 0 0 15px;
		left: 50px;
	}

	.first-square {
		width: 50px;
		height: 15px;
	}

	.last-square {
		width: calc(100% - 50px - 30px);
		left: 80px;
		height: 15px;
	}


	.color-white {
		background-color: @color-notch-white;
	}

	.color-triangle-left-white {
		border-color: transparent transparent transparent @color-notch-white;
		border-width: 15px 0 0 16px;
		left: 49px;
	}

	.color-triangle-right-white {
		border-color: transparent transparent @color-notch-white transparent;
		border-width: 0 0 15px 16px;
	}

	.color-blue {
		background-color: rgba(0, 18, 51, 0.8);
	}

	.color-triangle-left-blue {
		border-color: transparent transparent transparent rgba(0, 18, 51, 0.8);
	}

	.color-triangle-right-blue {
		border-color: transparent transparent rgba(0, 18, 51, 0.8) transparent;
	}

	.color-blue-solid {
		background-color: @color-purple;
	}

	.color-triangle-left-blue-solid {
		border-color: transparent transparent transparent @color-purple;
	}

	.color-triangle-right-blue-solid {
		border-color: transparent transparent @color-purple transparent;
	}

	.color-dark-blue {
		background-color: #001233;
	}

	.color-triangle-left-dark-blue {
		border-color: transparent transparent transparent #001233;
	}

	.color-triangle-right-dark-blue {
		border-color: transparent transparent #001233 transparent;
	}

}


.notch-container-wire {
	position: absolute;
	top: 0;
	right: calc(-@notch-border-width);
	width: calc(100% + @notch-border-width * 2);
	height: calc(15px + @notch-border-width);

	.first-square-wire {
		width: 50px;
		border-style: solid none none solid;
		height: 100%;
	}

	.last-square-wire {
		width: calc(100% - 50px - 30px);
		left: 80px;
		height: 100%;
		border-style: solid solid none none;
	}

	.wire-notch-triangle {
		border-style: none solid solid none;
		height: 25px;
		width: 25px;
		left: 50px;
		position: absolute;
		top: 0;
		transform: rotate(45deg) translate(-75%, -90%);
	}

	.color-wire-light-blue {
		border-color: #BFD2E1;
	}

	.wire-style {
		border-width: @notch-border-width;
	}
}

.notch {
	position: absolute;
	top: 0;
	transform: translate(0, -100%);
}

</style>
