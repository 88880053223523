var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "solid"
    ? _c(
        "div",
        {
          staticClass: "notch-container solid",
          class: ["margin-type-" + _vm.marginType],
        },
        [
          _c("div", {
            staticClass: "first-square notch",
            class: ["color-" + _vm.color],
          }),
          _c("div", {
            staticClass: "notch-triangle notch-right",
            class: ["color-triangle-right-" + _vm.color],
          }),
          _c("div", {
            staticClass: "notch-triangle notch-left",
            class: ["color-triangle-left-" + _vm.color],
          }),
          _c("div", {
            staticClass: "last-square notch",
            class: ["color-" + _vm.color],
          }),
        ]
      )
    : _vm.type === "wire"
    ? _c(
        "div",
        {
          staticClass: "notch-container-wire",
          class: ["margin-type-" + _vm.marginType],
        },
        [
          _c("div", {
            staticClass: "first-square-wire notch wire-style",
            class: ["color-wire-" + _vm.color],
          }),
          _c("div", {
            staticClass: "wire-notch-triangle wire-style",
            class: ["color-wire-" + _vm.color],
          }),
          _c("div", {
            staticClass: "last-square-wire notch wire-style",
            class: ["color-wire-" + _vm.color],
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }