<template>
    <weight-map :moment="moment" :momentId="momentId" :options="options" :points="weightMapPlayerVotes"
                :specificVoteCount="voteCount"/>

</template>

<script>
import PageComponentTemplate from '../page-component-template'
import WeightMap from './weight-map'

export default {
    name: 'page-component-weight-map-output',
    components: { WeightMap },
    extends: PageComponentTemplate,
    props: {
        options: {
            type: Object,
            required: false,
            default () {
                return {}
            },
        },
    },
    computed: {
        roomState () {
            return this.$gameClient?.room?.state || null;
        },
        currentCasePage() {
            if (!this.roomState) {
                return null;
            }

            return this.roomState.current_case_page;
        },
        momentId () {
            if (!this.roomState) {
                return;
            }

            let selectedChoiceIndex = this.roomState.selectedChoiceIndex;

            if (selectedChoiceIndex === null || selectedChoiceIndex === false && typeof(selectedChoiceIndex) === 'undefined') {
                return null;
            }

            if (selectedChoiceIndex < 0) {
                return null;
            }

            return selectedChoiceIndex;
        },
        moment () {
            let choices = this.currentPage?.component_left?.options?.choices || [];

            console.log('choices', this.momentId, choices.length);

            console.log({
                '1': this.currentPage,
                '2': this.currentPage?.component_left,
                '3': this.currentPage?.component_left?.options?.choices,
                '4': this.currentPage?.component_left?.options?.choices
            });

            if (this.momentId === null) {
                return null; // moment id could not be found yet
            }

            if (this.momentId >= choices.length) {
                return null; // not enough choices to have this moment id
            }

            let moment = choices[this.momentId];

            return moment.message || null;
        },
        voteCount () {
            if (!this.roomState) {
                return;
            }

            let voteCount = this.roomState.voteCounts[this.momentId]
            return voteCount
        },
        studentAnswerCount () {
            // Add up all the votes to get the total count
            //console.log(this.voteCounts, "votecounts", this.roomState.uniqueVoteCounts);
            //return this.voteCounts.reduce((a, b) => a + b, 0)
            return this.roomState ? this.roomState.uniqueVoteCounts : 0
        },
        weightMapPlayerVotes () {
            let weightMapPlayerVotes = [];

            if (this.roomState && this.roomState.weightMapPlayerVotes) {
                this.roomState.weightMapPlayerVotes.forEach(point => {
                    weightMapPlayerVotes.push({
                        x: point.x,
                        y: point.y,
                    });
                });
            }

            return weightMapPlayerVotes
        },
    },
}
</script>
