<template>
    <div class="results row mt-3">
        <div class="col text-center">
            <strong class="your-answers-headline">Your answers</strong>
            <div ref="container" class="result-bubble-container">
                <result-bubble
                    v-for="(result, i) in sortedResults"
                    v-if="i < maxBubbles"
                    :ref="'circle.' + i"
                    v-bind:key="i"
                    :class="['result-bubble', ('result-bubble-index-' + i)]"
                    :fill-color="result.fillColor"
                    :percentage="Math.round(result.percentage)"
                    :text-color="result.textColor"
                />
            </div>

        </div>
        <div class="col">
            <div>
                <!--<app-button button-style="dark">Se Danmarks svar</app-button>-->
            </div>
            <div>
                <ul class="result-legend">
                    <li
                        v-for="(result, i) in unsortedResults"
                    >
                        <span v-if="i >= maxBubbles" class="result-breadcrumb result-breadcrumb-empty"></span>
                        <span v-else :style="{ background: result.fillColor }" class="result-breadcrumb"></span>
                        <span class="result-legend">{{ result.message }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ChartTemplate from '../chart-template'
import ResultBubble from './result-bubble'
import clone from 'clone'
import { random } from '@/lib/circlepacker/util'
import { calcHeight, calcWidth } from '@/components/page-components/results/result-bubble-chart/util'
import * as CirclePacker from '@/lib/circlepacker/circlepacker'

export default {
    extends: ChartTemplate,
    name: 'result-bubble-chart',
    components: {
        ResultBubble,
    },
    data () {
        let resultFillColors = [
            '#888888', // 0 - Gray
            '#ffb703', // 1 - SelectiveYellow
            '#023047', // 2 - Green Vogue
            '#f23000', // 3 - Scarlet
        ]

        let resultTextColors = [
            '#ffffff', // 0
            '#ffffff', // 1
            '#ffffff', // 2
            '#ffffff', // 3
        ]

        return {
            maxBubbles: 5,

            boundsWidth: 0,
            boundsHeight: 0,

            containerElement: null,
            circleElements: {},
            innerCircles: {},

            packer: null,

            sortedResults: [],
            unsortedResults: [],
            resultFillColors: resultFillColors,
            resultTextColors: resultTextColors,
        }
    },
    mounted () {
        this.recalculateSortedResults()
    },
    beforeDestroy () {
        this.destroyCirclePacker()
    },
    watch: {
        results () {
            this.recalculateSortedResults()
        },
    },
    computed: {
        containerWidth () {
            return this.$refs['container'].clientWidth
        },
        containerHeight () {
            return this.$refs['container'].clientHeight
        },
    },
    methods: {
        recalculateSortedResults () {
            let sortedResults = clone(this.results)
            let unsortedResults = clone(this.results)

            for (let i = 0, len = sortedResults.length; i < len; i++) {
                sortedResults[i].fillColor = this.resultFillColors[i]
                sortedResults[i].textColor = this.resultTextColors[i]

                unsortedResults[i].fillColor = this.resultFillColors[i]
                unsortedResults[i].textColor = this.resultTextColors[i]
            }

            sortedResults.sort(function (a, b) {
                if (a.percentage < b.percentage) {
                    return 1
                }
                else if (a.percentage > b.percentage) {
                    return -1
                }
                else {
                    return 0
                }
            })

            this.sortedResults = sortedResults
            this.unsortedResults = unsortedResults

            this.$nextTick(() => {
                this.reinitializeCirclePacker()
            })
        },
        onOvalLoad (e) {
            //console.log('this', this, e);
        },

        // CIRCLE PACKER STUFF
        getCalculatedBounds () {
            return {
                width: this.containerWidth,
                height: this.containerHeight,
            }
        },
        getCalculatedTarget () {
            let bounds = this.getCalculatedBounds()

            return {
                x: bounds.width / 2,
                y: bounds.height / 2,
            }
        },

        reinitializeCirclePacker () {
            this.destroyCirclePacker()
            this.initializeCirclePacker()
        },

        destroyCirclePacker () {
            if (this.containerElement) {
                for (let id in this.circleElements) {
                    delete this.circleElements[id]
                }
            }

            this.circleElements = {}
            this.innerCircles = {}

            if (this.packer) {
                this.packer.destroy()
                this.packer = null
            }
        },

        initializeCirclePacker () {
            try {
                if (!this.sortedResults) {
                    return
                }

                if (this.packer) {
                    this.destroyCirclePacker()
                }

                // dimensions of container
                let bounds = this.getCalculatedBounds()
                let target = this.getCalculatedTarget()

                let circles = []

                let i = 0

                // The radius will be multiplied by this, so use a lower number if you want them to clump together more
                let clumpingMultiplier = 0.95

                for (let result of this.sortedResults) {
                    if (i >= this.maxBubbles) {
                        break
                    }

                    let width = calcWidth(result.percentage)
                    let height = calcHeight(result.percentage)

                    let circle = {
                        index: i,
                        ref: 'circle.' + i,
                        radius: (Math.max(width, height) / 2) * clumpingMultiplier,
                        width: width,
                        height: height,
                    }

                    circles.push(this.createCircle(circle))

                    i++
                }

                let animated = true

                let packerOptions = {
                    bounds: bounds,
                    target: target,
                    circles: circles,
                    onMove: this.renderCircles,
                }

                if (animated) {
                    packerOptions.continuousMode = true
                    packerOptions.collisionPasses = 3
                    packerOptions.centeringPasses = 2
                    packerOptions.damping = 0.025
                }
                else {
                    packerOptions.continuousMode = false
                    packerOptions.collisionPasses = 10
                    packerOptions.centeringPasses = 10
                }

                this.packer = new CirclePacker.default(packerOptions)

                if (!animated) {
                    this.packer.update()
                }
            }
            catch (e) {
                console.warn('Could not create circlepacker:', e)
            }
        },

        // create circle dom object, return circle data
        createCircle (circleDefinition) {
            let bounds = this.getCalculatedBounds()

            let radius = circleDefinition.radius
            let x = circleDefinition.x
            let y = circleDefinition.y

            if (typeof (x) === 'undefined' || x === null) {
                x = bounds.width / 2
            }

            if (typeof (y) === 'undefined' || y === null) {
                y = bounds.height / 2
            }

            let pushDistance = 1

            switch (circleDefinition.index) {
                case 0:
                    // 1st result (top one)

                    y -= pushDistance // Push it up a bit to try to make it on the top
                    x += Math.random() - 0.5 // randomize a bit so the algorithm still works

                    break
                case 1:
                    // 2st result

                    y += pushDistance // Push it up down a bit to try to make it appear on the bottom
                    x += Math.random() - 0.5 // randomize a bit so the algorithm still works

                    break
                case 2:
                    // 3st result

                    x -= pushDistance // Push it left a bit to try to make it appear on the left
                    y += Math.random() - 0.5 // randomize a bit so the algorithm still works

                    break
                case 3:
                    // 4st result (bottom one)

                    x += pushDistance // Push it right a bit to try to make it appear on the right
                    y += Math.random() - 0.5 // randomize a bit so the algorithm still works

                    break
            }

            const diameter = radius * 2
            const circleElement = this.$refs[circleDefinition.ref][0].$el

            // need some sort of unique id...
            const id = 'circle-' + random(0, 1000, true) + '-' + Date.now()

            const circle = {
                id: id,
                radius: radius,
                position: {
                    x: x,
                    y: y,
                },
            }

            this.circleElements[id] = circleElement
            this.innerCircles[id] = circle

            return circle
        },

        renderCircles (circles) {
            requestAnimationFrame(() => {
                for (let id in circles) {
                    const circleEl = this.circleElements[id]

                    if (circleEl) {
                        const circle = circles[id]
                        const x = circle.position.x - circle.radius
                        const y = circle.position.y - circle.radius

                        // actually move the circles around
                        circleEl.style.transform = `translateX(${x}px) translateY(${y}px)`
                        circleEl.classList.add('is-visible')
                    }
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
@import "~@less/variables.less";

.result-bubble-container {
    position: relative;
    height: 350px;

    .result-bubble {
        position: absolute;

        @baseZIndex: 10000;

        &.result-bubble-index-0 {
            z-index: @baseZIndex - 0;
        }

        &.result-bubble-index-2 {
            z-index: @baseZIndex - 1;
        }

        &.result-bubble-index-3 {
            z-index: @baseZIndex - 2;
        }

        &.result-bubble-index-4 {
            z-index: @baseZIndex - 3;
        }
    }
}

ul.result-legend {
    list-style-type: none;
    font-size: 20px;
    margin-top: 10px;

    li {
        border: 2px solid @color-green-vogue;
        padding: 0;
        position: relative;
        min-height: 80px;
        margin-bottom: 5px;
        border-radius: 10px;
        overflow: hidden;
    }

    .result-legend {
        position: absolute;
        top: 50%;
        left: 50px;
        transform: translateY(-50%);
    }

    .result-breadcrumb {
        position: absolute;
        top: 0;
        left: 0;

        width: 40px;
        height: 100%;
        display: inline-block;
        //border-radius: 10px;
        margin-right: 10px;

        &.result-breadcrumb-empty {
            border: 1px solid black;
            background: white;
        }
    }
}
</style>
