<template>
    <div class="results row mt-3">
        <div v-for="(result, i) in results" class="col">
            <div class="result-bar-container">
                <result-bar :fill-color="resultOptions[i].fillColor" :percentage="Math.round(result.percentage)"/>
            </div>
            <div class="result-bar-message-container">
                <div class="result-bar-message">
                    <div class="result-bar-message-inner">
                        {{ result.message }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChartTemplate from '../chart-template'
import ResultBar from './result-bar'

export default {
    extends: ChartTemplate,
    name: 'result-bar-chart',
    components: {
        ResultBar,
    },
    data () {
        let resultFillColors = [
            '#888888', // 0 - Gray
            '#ffb703', // 1 - SelectiveYellow
            '#023047', // 2 - Green Vogue
            '#f23000', // 3 - Scarlet
        ]

        let resultOptions = []

        for (let i = 0, len = resultFillColors.length; i < len; i++) {
            resultOptions.push({
                fillColor: resultFillColors[i],
            })
        }

        return {
            computedResults: [],
            resultOptions: resultOptions,
        }
    },
}
</script>

<style lang="less" scoped>

.result-bar-container {
    height: 300px;
    width: 80px;
    margin: 0 auto;
}

.result-bar-message-container {
    position: relative;
    width: 80px;
    margin: 0 auto 40px auto;
    top: 20px;
    left: 0px;
}

.result-bar-message {
    transform: rotate(45deg);
    transform-origin: top center;
    line-height: 100%;
    font-size: 12pt;

    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    /* Note: for a CLOCKWISE rotation, use the commented-out
       transform instead of this one. */
}

.result-bar-message-inner {
    position: relative;
    top: -5px;
    padding: 0 20px;
}
</style>
