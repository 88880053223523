var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "interactive-weight-map-container" },
    [
      _c("div", { staticClass: "background-color" }),
      _vm.interactive
        ? _c("div", { staticClass: "interactive-weight-map" }, [
            _c("div", { staticClass: "header" }, [
              _vm.momentId
                ? _c("div", { staticClass: "text-wrapper" }, [
                    _c("div", { staticClass: "moment-id" }, [
                      _vm._v("#" + _vm._s(this.momentIdFormatted)),
                    ]),
                    _c("div", { staticClass: "moment" }, [
                      _vm._v(_vm._s(this.moment)),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "place-token-start-wrapper" }, [
                -1 === _vm.point.y && -1 === _vm.point.x
                  ? _c("img", {
                      staticClass: "btn-point-new btn-sm ml-2",
                      attrs: { src: require("@img/misc/token.png") },
                    })
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "place-token-text" }, [
                _vm._v(_vm._s(_vm.$t("instructions.weightBoard2D"))),
              ]),
            ]),
            _c(
              "div",
              {
                ref: "board2D_wrapper",
                staticClass: "board2D-wrapper",
                on: { mouseup: _vm.clickBoard },
              },
              [
                _vm._l(_vm.points, function (listPoint) {
                  return listPoint.top === _vm.point.y &&
                    listPoint.left === _vm.point.x
                    ? _c("img", {
                        staticClass:
                          "btn-point-new btn-point-board btn-sm ml-2",
                        style: {
                          top: listPoint.top + "%",
                          left: listPoint.left + "%",
                        },
                        attrs: { src: require("@img/misc/token.png") },
                      })
                    : _vm._e()
                }),
                _vm._l(_vm.points, function (listPoint) {
                  return !(
                    listPoint.top === _vm.point.y &&
                    listPoint.left === _vm.point.x
                  )
                    ? _c("div", {
                        staticClass: "placeholder-tokens",
                        style: {
                          top: listPoint.top + "%",
                          left: listPoint.left + "%",
                        },
                      })
                    : _vm._e()
                }),
                _vm.boardType === _vm.BoardType.Vertical
                  ? _c("img", {
                      ref: "board2D",
                      staticClass: "board2D",
                      attrs: { src: _vm.boardImages.horizontal },
                    })
                  : _vm.boardType === _vm.BoardType.Horizontal
                  ? _c("img", {
                      ref: "board2D",
                      staticClass: "board2D",
                      attrs: { src: _vm.boardImages.vertical },
                    })
                  : _c("img", {
                      ref: "board2D",
                      staticClass: "board2D",
                      attrs: { src: _vm.boardImages.full },
                    }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "button-wrapper" },
              [
                _c(
                  "app-button-fit",
                  {
                    staticClass: "py-4",
                    attrs: { enabled: this.isPointChosen },
                    on: { onClick: _vm.submitPoint },
                  },
                  [_vm._v(_vm._s(_vm.$t("game.ready")) + "\n            ")]
                ),
              ],
              1
            ),
          ])
        : _c("weight-map", { attrs: { points: [_vm.point] } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }