<template>
    <div class="weight-map-container">
        <div v-if="babylonInitiated" class="ui-elements">
            <div class="instruction-wrapper">
                <div v-for="(instruction, i) in instructions" class="wireframe-space">
                    <text-wire-border :text="instruction" :title="$t('instructions.title')"/>
                </div>
            </div>
            <div v-if="moment" class="moment-wrapper">
                <choice :choiceType="'multipleAnswers'" :choices="[{text: moment}]" :selectable="false"
                        :showResults="true" :specificChoice="true" :specificId="momentId"
                        :specificVoteCount="specificVoteCount"/>
            </div>
            <div class="arrow-wrapper">
                <i class="fas fa-share arrow-turn-ccw" @click="turnBoard(90)"></i>
                <i class="fas fa-reply arrow-turn-cw" @click="turnBoard(-90)"></i>
            </div>
        </div>
        <div v-if="!babylonInitiated" class="loading-container"><span class="loading-text">Loading game board..</span>
        </div>
        <canvas ref="babylonCanvas" touch-action="none"></canvas>
        <!--<Box :position="[0, 0, 5]"></Box>-->
        <!-- 3d weight map here - show all the points in the points array -->
    </div>
</template>

<script>
import Choice from '@/components/choices'
import TextWireBorder from '@/components/page-components/text-wire-border'

/*import { Engine } from '@babylonjs/core/Engines/engine'
import { Scene } from '@babylonjs/core/scene'
import { Color3, Vector3 } from '@babylonjs/core/Maths/math'
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera'
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight'
import { DirectionalLight } from '@babylonjs/core/Lights/directionalLight'
import { Mesh } from '@babylonjs/core/Meshes/mesh'
import { Animation } from '@babylonjs/core/Animations/animation'

// Side-effects only imports allowing the standard material to be used as default.
import '@babylonjs/core/Materials/standardMaterial'
// Side-effects only imports allowing Mesh to create default shapes (to enhance tree shaking, the construction methods on mesh are not available if the meshbuilder has not been imported).
import '@babylonjs/core/Meshes/Builders/sphereBuilder'
import '@babylonjs/core/Meshes/Builders/boxBuilder'
import '@babylonjs/core/Meshes/Builders/groundBuilder'
import '@babylonjs/core/Behaviors/Cameras/framingBehavior'


import { ActionManager } from '@babylonjs/core/Actions/actionManager';
import { EasingFunction, QuadraticEase } from '@babylonjs/core/Animations/easing';
import { ExecuteCodeAction } from '@babylonjs/core/Actions/directActions';
import { ShadowGenerator } from '@babylonjs/core/Lights/Shadows/shadowGenerator';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Texture } from '@babylonjs/core/Materials/Textures/texture';
//import {} from '@babylonjs/core'*/
import { mapGetters } from 'vuex'

const Animation = BABYLON.Animation;
const Vector3 = BABYLON.Vector3;
const QuadraticEase = BABYLON.QuadraticEase;
const EasingFunction = BABYLON.EasingFunction;
const Engine = BABYLON.Engine;
const Scene = BABYLON.Scene;
const ArcRotateCamera = BABYLON.ArcRotateCamera;
const HemisphericLight = BABYLON.HemisphericLight;
const DirectionalLight = BABYLON.DirectionalLight;
const ActionManager = BABYLON.ActionManager;
const ExecuteCodeAction = BABYLON.ExecuteCodeAction;
const StandardMaterial = BABYLON.StandardMaterial;
const Texture = BABYLON.Texture;
const Color3 = BABYLON.Color3;
const Mesh = BABYLON.Mesh;
const ShadowGenerator = BABYLON.ShadowGenerator;

const boardImagesLocalized = {
    cn: require('@img/localized-assets/cn/board-texture-full.png'),
    cs: require('@img/localized-assets/cs/board-texture-full.png'),
    da: require('@img/localized-assets/da/board-texture-full.png'),
    de: require('@img/localized-assets/de/board-texture-full.png'),
    en: require('@img/localized-assets/en/board-texture-full.png'),
    es: require('@img/localized-assets/es/board-texture-full.png'),
    fr: require('@img/localized-assets/fr/board-texture-full.png'),
    pt: require('@img/localized-assets/pt/board-texture-full.png'),
};

export default {
    name: 'weight-map',
    components: {
        Choice,
        TextWireBorder,
    },
    props: {
        points: {
            required: true,
        },
        options: {
            type: Object,
            required: false,
            default () {
                return {}
            },
        },
        moment: {
            type: String,
            required: false,
            default: null,
        },
        momentId: {
            type: Number,
            required: false,
        },
        specificVoteCount: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data () {
        return {
            frameRate: 50,
            maxTokens: 22,
            camera: null,
            babylonInitiated: false,
            scene: null,
            parentElem: null,
            engine: null,
        }
    },
    methods: {
        makeMoveAnimation (frameRate, tokenHeight, easingFunction, delay) {
            let moveDown_keys = []

            var moveDown = new Animation(
                'movein',
                'position.y',
                frameRate,
                Animation.ANIMATIONTYPE_FLOAT,
                Animation.ANIMATIONLOOPMODE_CONSTANT,
            )

            moveDown.setEasingFunction(easingFunction)

            moveDown_keys.push({
                frame: 0,
                value: tokenHeight + 1.1,
            })

            moveDown_keys.push({
                frame: delay,
                value: tokenHeight + 1.1,
            })

            moveDown_keys.push({
                frame: frameRate - 8 + delay,
                value: tokenHeight,
            })

            moveDown_keys.push({
                frame: frameRate - 4 + delay,
                value: tokenHeight + 0.07,
            })

            moveDown_keys.push({
                frame: frameRate + delay,
                value: tokenHeight,
            })
            moveDown.setKeys(moveDown_keys)

            return moveDown
        },
        makeFadeAnimation (frameRate, delay) {
            var fadeIn = new Animation(
                'fadeIn',
                'visibility',
                frameRate,
                Animation.ANIMATIONTYPE_FLOAT,
                Animation.ANIMATIONLOOPMODE_CONSTANT,
            )

            var fadeIn_keys = []

            fadeIn_keys.push({
                frame: 0,
                value: 0,
            })

            fadeIn_keys.push({
                frame: delay,
                value: 0,
            })

            fadeIn_keys.push({
                frame: delay + frameRate / 2,
                value: 1,
            })

            fadeIn.setKeys(fadeIn_keys)

            return fadeIn
        },
        calculateBoardRotation (weightList) {
            // calculate board tilt
            let meanValX
            let meanValY
            let sumX = 0
            let sumY = 0
            let nTokens = 0

            for (let key in weightList) {
                if (weightList.hasOwnProperty(key)) {
                    let point = key.split(',')
                    sumX += point[0] * weightList[key]
                    sumY += point[1] * weightList[key]

                    nTokens += weightList[key]
                }
            }

            meanValX = sumX / nTokens
            meanValY = sumY / nTokens

            // rotate board (0.2 should be max, but the sum of the two should not go beyond 0.165;
            let rotMaxDouble = 0.165
            let rotMax = rotMaxDouble / 2
            let xRot = (rotMaxDouble * (meanValX / 100)) - rotMax
            let zRot = ((rotMaxDouble * (meanValY / 100)) - rotMax) * -1

            let fullRot = Math.abs(xRot) + Math.abs(zRot)

            let rotXPercentage = 0;
            let rotYPercentage = 0;

            if (fullRot !== 0) {
              rotXPercentage = xRot / fullRot
              rotYPercentage = zRot / fullRot
            }

            xRot = rotMaxDouble * rotXPercentage
            zRot = rotMaxDouble * rotYPercentage

            let yRot = 0

            return new Vector3(xRot, yRot, zRot)
        },
        makeRotationAnimation (frameRate, rotation, easingFunction) {
            let rotate = new Animation(
                'fadeIn',
                'rotation',
                frameRate,
                Animation.ANIMATIONTYPE_VECTOR3,
                Animation.ANIMATIONLOOPMODE_CONSTANT,
            )

            rotate.setEasingFunction(easingFunction)

            let rotate_keys = []

            rotate_keys.push({
                frame: 0,
                value: new Vector3(0, 0, 0),
            })

            rotate_keys.push({
                frame: frameRate,
                value: rotation,
            })

            rotate.setKeys(rotate_keys)

            return rotate
        },
        radiansToDegrees (radians) {
            return radians * (180 / Math.PI)
        },
        degreeToRadians (degrees) {
            return degrees * (Math.PI / 180)
        },
        snapTo (value, snapTo) {
            return Math.round(value / snapTo) * snapTo
        },
        turnBoardNew (degrees) {
            /*let sourceRotationRadians = this.camera.alpha;
            let sourceRotationDegrees = this.radiansToDegrees(sourceRotationRadians);

            let destinationRotationDegrees = sourceRotationDegrees + 10; // this.snapTo(sourceRotationDegrees + degrees, 90);
            let destinationRotationRadians = this.degreeToRadians(destinationRotationDegrees);

            createjs.Tween.get(this.camera).to({ alpha:destinationRotationRadians }, 1000, createjs.Ease.bounceInOut)

            this.camera.alpha = destinationRotationRadians;

            return;*/

            let easingFunction = new QuadraticEase()
            easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT)

            let rotate = new Animation(
                'fadeIn',
                'alpha',
                this.frameRate,
                Animation.ANIMATIONTYPE_VECTOR3,
                Animation.ANIMATIONLOOPMODE_CONSTANT,
            )

            rotate.setEasingFunction(easingFunction)

            let rotateKeys = []

            rotateKeys.push({
                frame: 0,
                value: sourceRotationRadians,
            })

            rotateKeys.push({
                frame: this.frameRate,
                value: destinationRotationRadians,
            })

            rotate.setKeys(rotateKeys)

            this.scene.beginDirectAnimation(this.camera, [rotate], 0, this.frameRate, false)
        },
        turnBoard (degrees) {

            let rotInRadians = (degrees * Math.PI) / 180

            let easingFunction = new QuadraticEase()
            easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT)

            let rotate = new Animation(
                'fadeIn',
                'rotation',
                this.frameRate,
                Animation.ANIMATIONTYPE_VECTOR3,
                Animation.ANIMATIONLOOPMODE_CONSTANT,
            )

            rotate.setEasingFunction(easingFunction)

            let rotate_keys = []
            let originalRotation = this.parentElem.rotation
            let newRotation = new Vector3(0 + originalRotation.x, rotInRadians + originalRotation.y,
                0 + originalRotation.z)

            rotate_keys.push({
                frame: 0,
                value: originalRotation,
            })

            rotate_keys.push({
                frame: this.frameRate,
                value: newRotation,
            })

            rotate.setKeys(rotate_keys)

            this.scene.beginDirectAnimation(this.parentElem, [rotate], 0, this.frameRate, false)
        },
        disposeBabylon () {
            if (this.scene) {
                try {
                    this.scene.dispose()
                } catch (e) {
                    // oh well
                }

                this.scene = null
            }

            if (this.engine) {
                try {
                    this.engine.dispose()
                } catch (e) {
                    // oh well
                }

                this.engine = null
            }

            this.parentElem = null

            this.babylonInitiated = false
        },
        initBabylon () {
            // console.log("method call", this.makeMoveAnimation(0,0));

            const canvas = this.$refs['babylonCanvas']
            const engine = new Engine(canvas)

            this.engine = engine
            this.scene = new Scene(engine)

            console.log('Scene', this.scene, engine, canvas);

            this.scene.createDefaultEnvironment()
            // scene.clearColor = Color3.FromHexString('#283381');

            const beta = Math.PI / 3
            const camera = new ArcRotateCamera('camera', 0, beta, 10, new Vector3(0, 1.5, 0), this.scene)
            this.scene.activeCamera.useFramingBehavior = true
            let framingBehavior = this.scene.activeCamera.getBehaviorByName('Framing')
            framingBehavior.framingTime = 0
            framingBehavior.elevationReturnTime = -1
            let worldExtends = this.scene.getWorldExtends()
            this.scene.activeCamera.lowerRadiusLimit = null
            framingBehavior.zoomOnBoundingInfo(worldExtends.min, worldExtends.max)

            //camera.minZ = 5;
            //camera.maxZ = 15;
            camera.allowUpsideDown = false
            // this needs to be added again but is removed because of dev
            camera.inputs.remove(camera.inputs.attached.mousewheel)
            camera.lowerBetaLimit = beta
            camera.upperBetaLimit = beta

            // This attaches the camera to the canvas
            camera.attachControl(canvas, true)

            this.camera = camera

            // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
            let light = new HemisphericLight('light1', new Vector3(0, 1, 0), this.scene)

            // Default intensity is 1. Let's dim the light a small amount
            light.intensity = 0.6

            var light1 = new DirectionalLight('dir01', new Vector3(-1, -2, -1), this.scene)
            light1.position = new Vector3(0, 15, 0)
            light1.intensity = 0.5

            // create board
            let board = Mesh.CreateBox(
                {
                    name: 'Box',
                    size: 20,
                    scene: this.scene,
                })

            board.actionManager = new ActionManager(this.scene);

            board.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, (ev) => {
                this.scene.hoverCursor = 'grab';
            }));

            board.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickDownTrigger, (ev) => {
                this.scene.hoverCursor = 'grabbing';
            }));

            board.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger, (ev) => {
                this.scene.hoverCursor = 'grab';
            }));

            //ON MOUSE EXIT
            board.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, (ev) => {
                this.scene.hoverCursor = 'normal';
            }));

            board.position = new Vector3(0, 0.8, 0)
            let boardLength = 5
            let boardHeight = 0.01
            board.scaling.x = boardLength
            board.scaling.y = boardHeight
            board.scaling.z = boardLength

            let boardTexture = new StandardMaterial('boardTexture', this.scene)

            let image;

            if (this.language) {
                console.log('Picking ' + this.language + ' version of 3d board images');
                image = boardImagesLocalized[this.language];
            } else {
                console.log('Picking english version of 3d board images');
                image = boardImagesLocalized.en;
            }

            boardTexture.diffuseTexture = new Texture(image)
            //boardTexture.disableLighting = true;
            boardTexture.emissiveColor = new Color3(1, 1, 1)

            board.material = boardTexture

            let boardFiller = Mesh.CreateBox(
                {
                    name: 'Box',
                    size: 20,
                    scene: this.scene,
                })
            boardFiller.position = new Vector3(0, 0.75, 0)
            let bordFillerHeight = 0.09
            boardFiller.scaling.x = boardLength
            boardFiller.scaling.y = bordFillerHeight
            boardFiller.scaling.z = boardLength

            let boardFillerTexture = new StandardMaterial('boardFillerTexture', this.scene)
            boardFillerTexture.diffuseColor = new Color3.FromHexString('#ffffff')
            boardFillerTexture.emissiveColor = new Color3(.5, .5, .5)

            boardFiller.material = boardFillerTexture

            let groundMat = new StandardMaterial('redMat', this.scene)
            groundMat.diffuseColor = new Color3(0, 0, 1)
            groundMat.alpha = 0

            this.parentElem = Mesh.CreateBox('box', 0.4, this.scene)
            this.parentElem.material = groundMat

            board.parent = this.parentElem
            boardFiller.parent = this.parentElem

            // actually green
            let blueMat = new StandardMaterial('redMat', this.scene)
            blueMat.diffuseColor = new Color3.FromHexString('#52BE80')
            blueMat.alpha = 0.9

            const boardTokenLength = 4.3
            const boardTokenLength2 = boardTokenLength / 2
            let tokenHeightScale = 0.4
            const tokenSpaceBetween = 0.01
            const tokenWidth = 0.35

            let weightList = {}
            let weightHeightList = {}

            //box fall
            let easingFunction = new QuadraticEase()
            easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEIN)

            // make boxes
            let counter = 0
            let moveAnimations = []
            let fadeAnimations = []
            let boxArray = []
            let animationDelay = 0
            let alphaIndexes = []

            // For testing
            // this.points = [{x: 50, y:80}];
            // this.points = [{x: 50, y:90}, {x: 50, y:10}];

            // to ensure that token height is correct
            let maxHeight = 0
            for (let point of this.points) {
                let numberOfTokens = 0
                if (weightHeightList['' + point.x + ',' + point.y] !== undefined) {
                    numberOfTokens = weightHeightList['' + point.x + ',' + point.y]
                }
                weightHeightList['' + point.x + ',' + point.y] = numberOfTokens + 1

                if (weightHeightList['' + point.x + ',' + point.y] > maxHeight) {
                    maxHeight = weightHeightList['' + point.x + ',' + point.y]
                }
            }

            let maxHeightDelta = maxHeight - this.maxTokens

            let minHeight = 0.1
            let maxDelta = 50

            if (maxHeightDelta > 0) {
                //make tiles smaller if above maxTokens
                if (maxHeightDelta > maxDelta) {
                    tokenHeightScale = maxHeightDelta
                }
                else {
                    let scale = 1 - (maxHeightDelta / maxDelta)
                    tokenHeightScale = (tokenHeightScale - minHeight) * scale + minHeight
                }
            }

            const speedRatio = 2.5;

            for (let point of this.points) {
                let numberOfTokens = 0
                if (weightList['' + point.x + ',' + point.y] !== undefined) {
                    numberOfTokens = weightList['' + point.x + ',' + point.y]
                }
                weightList['' + point.x + ',' + point.y] = numberOfTokens + 1

                // if(animationKeyframes.length <= numberOfTokens) {
                //let moveDown_keys = [];
                let defHeight = 0.9 + ((tokenHeightScale + tokenSpaceBetween) * tokenWidth * (numberOfTokens))

                animationDelay += 10;
                let moveAnimation = this.makeMoveAnimation(this.frameRate, defHeight, easingFunction, animationDelay)
                let fadeInAnimation = this.makeFadeAnimation(this.frameRate, animationDelay)
                //animationKeyframes.push(moveDown_keys)
                //moveDown.setKeys(animationKeyframes[numberOfTokens]);
                moveAnimations.push(moveAnimation)
                fadeAnimations.push(fadeInAnimation)
                // }

                let zPos = (boardTokenLength * (point.x / 100)) - boardTokenLength2
                let xPos = (boardTokenLength * (point.y / 100)) - boardTokenLength2

                let wobbleX = Math.random() * 0.08
                let wobbleZ = Math.random() * 0.1

                let sign = wobbleZ < 0.05 ? -1 : 1
                let rotY = Math.random() * 0.4 * sign

                let box = Mesh.CreateBox('box', tokenWidth, this.scene)
                alphaIndexes.push(numberOfTokens)
                // box.alphaIndex = numberOfTokens;
                box.visibility = 0
                box.position.y = 0.9 + ((tokenHeightScale + tokenSpaceBetween) * tokenWidth * (numberOfTokens)) + 1.1
                box.position.x = xPos + wobbleX
                box.position.z = zPos + wobbleZ
                box.rotation.y = rotY
                box.scaling.y = tokenHeightScale

                box.material = blueMat
                box.parent = this.parentElem

                boxArray.push(box)

                if (counter + 1 !== this.points.length) {
                    this.scene.beginDirectAnimation(boxArray[counter],
                        [moveAnimations[counter], fadeAnimations[counter]], 0, (this.frameRate * 2 + animationDelay),
                        false, speedRatio)
                }
                else {
                    this.scene.beginDirectAnimation(boxArray[counter],
                        [moveAnimations[counter], fadeAnimations[counter]], 0, this.frameRate * 2 + animationDelay,
                        false, speedRatio, () => {
                            let boxCounter = 0
                            for (let box of boxArray) {
                                box.alphaIndex = alphaIndexes[boxCounter]
                                boxCounter++
                            }

                            // tilt board
                            let boardRotation = this.calculateBoardRotation(weightList)
                            let rotationAnimation = this.makeRotationAnimation(this.frameRate, boardRotation,
                                easingFunction)
                            this.scene.beginDirectAnimation(this.parentElem, [rotationAnimation], 0, this.frameRate,
                                false, speedRatio)
                        })
                }
                counter++
            }
            /*
                  parentElem.rotation = new Vector3(xRot, yRot, zRot);

            */
            // ground shadow
            let groundLength = boardLength * 1.4
            let ground = Mesh.CreateGround('ground1', groundLength, groundLength, 2, this.scene)
            ground.position.y = 0.08

            let groundMaterial = new StandardMaterial('ground', this.scene)
            let imageGroundShadow = require('@img/misc/shadow-3d-board.png')
            groundMaterial.diffuseTexture = new Texture(imageGroundShadow, this.scene)
            groundMaterial.diffuseTexture.hasAlpha = true
            groundMaterial.useAlphaFromDiffuseTexture = true
            groundMaterial.disableLighting = false
            groundMaterial.backFaceCulling = false
            groundMaterial.transparencyMode = 2
            groundMaterial.alpha = 0.8
            groundMaterial.shadowLevel = 0.4

            ground.material = groundMaterial
            // ground.material = groundMat;

            let generator = new ShadowGenerator(2000, light1)
            generator.addShadowCaster(board)

            engine.runRenderLoop(() => {
                this.scene.render()
            })

            this.babylonInitiated = true
        },
    },
    computed: {
        ...mapGetters({
            language: 'getLanguage',
            currentPageIndex: 'getCurrentPageIndex',
            currentPagePhase: 'getCurrentPagePhase',
        }),
        instructions () {
            if (this.options.instructions && this.options.instructions[this.currentPagePhase]?.instructions) {
              return this.options.instructions[this.currentPagePhase].instructions
            }
            return []
        },
    },
    mounted () {
        this.$nextTick(() => {
            // Should not be required, but let's just be on the safe side
            this.disposeBabylon()

            this.initBabylon()
        })
    },
    beforeDestroy () {
        this.disposeBabylon()
    },
}
</script>

<style lang="less" scoped>
.ui-elements {
    z-index: 99;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50px;

    .moment-wrapper {
        width: calc(65vw);
        top: -20px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .instruction-wrapper {
        width: calc(20vw);
        position: absolute;
        top: -20px;
        left: 25px;
    }

    .arrow-wrapper {
        color: white;
        font-size: xxx-large;
        position: fixed;
        left: 50%;
        bottom: 80px;
        transform: translateX(-50%);

        i {
            padding: 25px;
        }

        .arrow-turn-cw {
            transform: rotate(45deg);
        }

        .arrow-turn-ccw {
            transform: rotate(-45deg);
        }
    }
}

.counter-container {
    position: fixed;
    bottom: 85px;
    left: 80px;
    z-index: 999999;
}

canvas, .loading-container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
}

.loading-container {
    background: #002D74;

    .loading-text {
        color: white;
        font-size: 60px;
        font-weight: bold;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-shadow: 3px 5px 2px #474747;
    }
}

.wireframe-space {
    margin-top: 15px;
    margin-bottom: 30px;
}
</style>
