var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "results row mt-3" }, [
    _c("div", { staticClass: "col text-center" }, [
      _c("strong", { staticClass: "your-answers-headline" }, [
        _vm._v("Your answers"),
      ]),
      _c(
        "div",
        { ref: "container", staticClass: "result-bubble-container" },
        _vm._l(_vm.sortedResults, function (result, i) {
          return i < _vm.maxBubbles
            ? _c("result-bubble", {
                key: i,
                ref: "circle." + i,
                refInFor: true,
                class: ["result-bubble", "result-bubble-index-" + i],
                attrs: {
                  "fill-color": result.fillColor,
                  percentage: Math.round(result.percentage),
                  "text-color": result.textColor,
                },
              })
            : _vm._e()
        }),
        1
      ),
    ]),
    _c("div", { staticClass: "col" }, [
      _c("div"),
      _c("div", [
        _c(
          "ul",
          { staticClass: "result-legend" },
          _vm._l(_vm.unsortedResults, function (result, i) {
            return _c("li", [
              i >= _vm.maxBubbles
                ? _c("span", {
                    staticClass: "result-breadcrumb result-breadcrumb-empty",
                  })
                : _c("span", {
                    staticClass: "result-breadcrumb",
                    style: { background: result.fillColor },
                  }),
              _c("span", { staticClass: "result-legend" }, [
                _vm._v(_vm._s(result.message)),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }