var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "timer",
      class: { danger: _vm.timeRemaining <= 10 && _vm.timeRemaining > 0 },
      style: { width: this.width, height: this.height },
    },
    [
      _c(
        "svg",
        {
          attrs: {
            fill: "none",
            height: "100%",
            viewbox: "0 0 50 50",
            width: "100%",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            class: [
              "segment",
              _vm.segmentActive >= 12 ? "segment-active" : "segment-inactive",
            ],
            attrs: {
              d: "M61.5496 7.05578V0C57.624 0.0619835 53.719 0.485537 49.907 1.26033C45.8884 2.08678 41.9422 3.30578 38.1715 4.90702C36.095 5.78512 34.0496 6.78719 32.0764 7.89256L35.6095 14.0186C43.3161 9.72107 52.1488 7.21074 61.5496 7.05578Z",
              fill: "white",
            },
          }),
          _c("path", {
            class: [
              "segment",
              _vm.segmentActive >= 11 ? "segment-active" : "segment-inactive",
            ],
            attrs: {
              d: "M33.9566 14.969L30.4236 8.84297C29.4525 9.42148 28.4917 10.031 27.5517 10.6715C24.2459 12.9029 21.126 15.4752 18.3058 18.3058C15.4752 21.1364 12.9132 24.2459 10.6715 27.5517C10.0413 28.4917 9.43182 29.4525 8.84298 30.4236L14.969 33.9566C19.6591 26.188 26.188 19.6591 33.9566 14.969Z",
              fill: "white",
            },
          }),
          _c("path", {
            class: [
              "segment",
              _vm.segmentActive >= 10 ? "segment-active" : "segment-inactive",
            ],
            attrs: {
              d: "M14.0186 35.6095L7.89256 32.0764C6.78719 34.0599 5.78512 36.095 4.90702 38.1715C3.30578 41.9422 2.08678 45.8884 1.26033 49.907C0.485537 53.719 0.0619835 57.624 0 61.5496H7.05578C7.21074 52.1488 9.72107 43.3161 14.0186 35.6095Z",
              fill: "white",
            },
          }),
          _c("path", {
            class: [
              "segment",
              _vm.segmentActive >= 9 ? "segment-active" : "segment-inactive",
            ],
            attrs: {
              d: "M7.05578 63.4504H0C0.0619835 67.376 0.485537 71.281 1.26033 75.093C2.08678 79.1116 3.30578 83.0579 4.90702 86.8285C5.78512 88.905 6.78719 90.9504 7.89256 92.9235L14.0186 89.3905C9.72107 81.6839 7.21074 72.8512 7.05578 63.4504Z",
              fill: "white",
            },
          }),
          _c("path", {
            class: [
              "segment",
              _vm.segmentActive >= 8 ? "segment-active" : "segment-inactive",
            ],
            attrs: {
              d: "M14.969 91.0434L8.84298 94.5764C9.42149 95.5475 10.031 96.5083 10.6715 97.4483C12.9029 100.754 15.4752 103.874 18.3058 106.694C21.1364 109.525 24.2459 112.087 27.5517 114.329C28.4917 114.959 29.4525 115.568 30.4236 116.157L33.9566 110.031C26.188 105.341 19.6591 98.812 14.969 91.0434Z",
              fill: "white",
            },
          }),
          _c("path", {
            class: [
              "segment",
              _vm.segmentActive >= 7 ? "segment-active" : "segment-inactive",
            ],
            attrs: {
              d: "M35.6095 110.981L32.0764 117.107C34.0599 118.213 36.095 119.215 38.1715 120.093C41.9422 121.694 45.8988 122.913 49.907 123.74C53.719 124.514 57.624 124.938 61.5496 125V117.944C52.1488 117.789 43.3161 115.279 35.6095 110.981Z",
              fill: "white",
            },
          }),
          _c("path", {
            class: [
              "segment",
              _vm.segmentActive >= 6 ? "segment-active" : "segment-inactive",
            ],
            attrs: {
              d: "M63.4504 117.944V125C67.3657 124.938 71.281 124.514 75.093 123.74C79.1116 122.913 83.0579 121.694 86.8285 120.093C88.905 119.215 90.9504 118.213 92.9235 117.107L89.3905 110.981C81.6839 115.279 72.8512 117.789 63.4504 117.944Z",
              fill: "white",
            },
          }),
          _c("path", {
            class: [
              "segment",
              _vm.segmentActive >= 5 ? "segment-active" : "segment-inactive",
            ],
            attrs: {
              d: "M91.0434 110.031L94.5764 116.157C95.5475 115.579 96.5083 114.969 97.4483 114.329C100.754 112.097 103.874 109.525 106.694 106.694C109.525 103.864 112.087 100.754 114.329 97.4483C114.959 96.5083 115.568 95.5475 116.157 94.5764L110.031 91.0434C105.341 98.812 98.812 105.341 91.0434 110.031Z",
              fill: "white",
            },
          }),
          _c("path", {
            class: [
              "segment",
              _vm.segmentActive >= 4 ? "segment-active" : "segment-inactive",
            ],
            attrs: {
              d: "M123.74 75.093C124.514 71.281 124.938 67.376 125 63.4504H117.944C117.789 72.8512 115.279 81.6839 110.981 89.3905L117.107 92.9235C118.213 90.9401 119.215 88.905 120.093 86.8285C121.694 83.0579 122.913 79.1116 123.74 75.093Z",
              fill: "white",
            },
          }),
          _c("path", {
            class: [
              "segment",
              _vm.segmentActive >= 3 ? "segment-active" : "segment-inactive",
            ],
            attrs: {
              d: "M117.944 61.5496H125C124.938 57.6343 124.514 53.719 123.74 49.907C122.913 45.8884 121.694 41.9422 120.093 38.1715C119.215 36.095 118.213 34.0496 117.107 32.0764L110.981 35.6095C115.279 43.3161 117.789 52.1488 117.944 61.5496Z",
              fill: "white",
            },
          }),
          _c("path", {
            class: [
              "segment",
              _vm.segmentActive >= 2 ? "segment-active" : "segment-inactive",
            ],
            attrs: {
              d: "M110.031 33.9566L116.157 30.4236C115.579 29.4525 114.969 28.4917 114.329 27.5517C112.097 24.2459 109.525 21.126 106.694 18.3058C103.864 15.4752 100.754 12.9132 97.4483 10.6715C96.5083 10.0413 95.5475 9.43181 94.5764 8.84297L91.0434 14.969C98.812 19.6591 105.341 26.188 110.031 33.9566Z",
              fill: "white",
            },
          }),
          _c("path", {
            class: [
              "segment",
              _vm.segmentActive >= 1 ? "segment-active" : "segment-inactive",
            ],
            attrs: {
              d: "M89.3905 14.0186L92.9235 7.89256C90.9401 6.78719 88.905 5.78512 86.8285 4.90702C83.0579 3.30578 79.1116 2.08678 75.093 1.26033C71.281 0.485537 67.376 0.0619835 63.4504 0V7.05578C72.8512 7.21074 81.6839 9.72107 89.3905 14.0186Z",
              fill: "white",
            },
          }),
        ]
      ),
      _c("div", { staticClass: "timer-text" }, [
        _vm._v(_vm._s(_vm.formatTime(_vm.timeRemaining))),
        _c("br"),
        _vm._v(_vm._s(_vm.$t("game.timerSeconds"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }