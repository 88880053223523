export default {
	PhaseChanged: 'gameClient:phaseChanged',
	CustomRoomIdChanged: 'gameClient:customRoomIdChanged',
	EndPointChanged: 'gameClient:onEndPointChanged',
	Error: 'gameClient:onError',
	Close: 'gameClient:onClose',
	Open: 'gameClient:onOpen',
	RoomJoin: 'gameClient:room.onJoin',
	RoomError: 'gameClient:room.onError',
	RoomMessage: 'gameClient:room.onMessage',
	RoomLeave: 'gameClient:room.onLeave',
	RoomStateChange: 'gameClient:room.onStateChange',
	RoomCreated: 'gameClient:room.onCreated',
	Disconnected: 'gameClient:onDisconnected',

	CaseInfoChanged: 'gameClient:case.onChange',
	CaseCurrentPageChanged: 'gameClient:case.onCurrentPageChanged',
}
