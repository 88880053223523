<template>
    <div class="container">
        <component :is="chartComponent"
                   :options="options"
                   :results="resultsPercentage"/>
    </div>
</template>

<script>
import ResultBubbleChart from '@/components/page-components/results/result-bubble-chart/index.vue'
import ResultBubbleChartOld from '@/components/page-components/results/result-bubble-chart-old/index.vue'
import ResultBarChart from '@/components/page-components/results/result-bar-chart/index.vue'

export default {
    name: 'chart-container',
    components: {
        ResultBubbleChart,
        ResultBarChart,
        ResultBubbleChartOld,
    },
    props: {
        chartStyle: {
            type: String,
        },
        options: {
            type: Object,
        },
        results: {
            type: Array,
        },
        uniqueUsers: {
            type: Number,
        },
    },
    data () {
        return {
            resultsPercentage: [],
        }
    },
    computed: {
        chartComponent () {
            if (this.chartStyle === 'bubbleChart') {
                return ResultBubbleChart
            }
            else if (this.chartStyle === 'barChart') {
                return ResultBarChart
            }
            else if (this.chartStyle === 'bubbleChartOld') {
                return ResultBubbleChartOld
            }
        },
    },
    mounted () {
        this.recalculateResultsPercentage()
    },
    watch: {
        results: {
            handler () {
                this.recalculateResultsPercentage()
            },
            deep: true,
        },
    },
    methods: {
        recalculateResultsPercentage () {
            let resultsPercentage = []

            let total = 0
            let maxValue = 0

            for (let result of this.results) {
                if (!result.hasOwnProperty('value')) {
                    continue
                }

                let value = result.value

                if (!(value instanceof Number)) {
                    value = parseInt(value, 10)
                }

                maxValue = Math.max(maxValue, value)

                if (!Number.isNaN(value) && value > 0) {
                    total += result.value
                }
            }

            if (this.chartStyle === 'barChart') {
                // Base it on unique answers!
                for (let result of this.results) {
                    resultsPercentage.push({
                        percentage: this.uniqueUsers > 0 ? (result.value / this.uniqueUsers) * 100 : 0,
                        message: result.message,
                    })
                }
            }
            else {
                for (let result of this.results) {
                    resultsPercentage.push({
                        percentage: total > 0 ? (result.value / total) * 100 : 0,
                        message: result.message,
                    })
                }
            }

            /*console.log('chartContainer.resultDebug', {
              total: total,
              results: JSON.stringify(this.results),
              resultsPercentage: JSON.stringify(resultsPercentage),
              this: this
            });*/

            this.resultsPercentage = resultsPercentage
        },
        pickChoice (choiceIndex) {
            let choice = this.choices[choiceIndex]

            this.$emit('pickChoice', choice)
        },
    },
}
</script>
<style lang="less" scoped>
@import "~@less/font-sizes.less";
</style>
